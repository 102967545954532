import React, { useState } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Layout from "./Layout";
import AdminLogin from "./Pages/Login/AdminLogin";
import WelcomePage from "./Pages/WelcomePage";
import UploadedDetails from "./Pages/Uploaded/UploadedDetails";
import ListMovies from "./Pages/ListMovies/ListMovies";
import MarketPlace from "./Pages/MarketPlace/MarketPlace";
import Transaction from "./Pages/Transaction/Transaction";
import ListMovieDetails from "./Pages/ListMovies/ListMovieDetails";
import MarketDetail from "./Pages/MarketPlace/MarketDetail";
import AccountTab from "./Pages/AccountCreation/AccountTab";
import EnquiryForm from "./Pages/Enquiry/EnquiryForm";
import UploadsTab from "./Pages/Uploaded/UploadsTab";
import UpdateUpload from "./Pages/Uploaded/UpdateUpload";
import CMS from "./Pages/CMS/CMS";

//this variable is for local development
export const startUrl = `/`;

function Routes() {
  //  const auth = localStorage.getItem('BazaarAdminAuth');
  const [successOpen, setsuccessOpen] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [successStatus, setsuccessStatus] = useState(false);
  const [successColor, setsuccessColor] = useState(false);
  const auth = false;

  return useRoutes([
    {
      path: startUrl,
      element: (
        <AdminLogin
          setsuccessOpen={setsuccessOpen}
          setsuccessMessage={setsuccessMessage}
          setsuccessStatus={setsuccessStatus}
          setsuccessColor={setsuccessColor}
        />
      ),
    },
    {
      path: `${startUrl}app`,
      element: !auth ? (
        <Layout
          successOpen={successOpen}
          setsuccessOpen={setsuccessOpen}
          successMessage={successMessage}
          successStatus={successStatus}
          successColor={successColor}
        />
      ) : (
        <Navigate to={startUrl} />
      ),
      children: [
        {
          path: `${startUrl}app`,
          element: <Navigate to={`home`} />,
        },
        {
          path: "home",
          element: <WelcomePage />,
        },
        {
          path: "uploads",
          element: <UploadsTab />,
        },
        {
          path: "uploads/uploadeddetail",
          element: <UploadedDetails />,
        },
        {
          path: "uploads/updateuploads",
          element: <UpdateUpload />,
        },
        {
          path: "listing",
          element: <ListMovies />,
        },
        {
          path: "listing/listmoviedetail",
          element: <ListMovieDetails />,
        },
        {
          path: "marketplace",
          element: <MarketPlace />,
        },
        {
          path: "marketplace/marketdetail",
          element: <MarketDetail />,
        },
        {
          path: "transaction",
          element: <Transaction />,
        },

        {
          path: "account",
          element: <AccountTab />,
        },

        {
          path: "enquiry",
          element: <EnquiryForm />,
        },
        {
          path: "cms",
          element: <CMS />,
        },

        { path: `logout`, element: <Navigate to={startUrl} /> },
      ],
    },
  ]);
}

export default Routes;

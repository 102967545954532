import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box,
  Container,
  Stack,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import SnackBar from "../../components/SnackBar";
import Heading from "../../components/Heading/Heading";
import {
  add_blogs,
  add_sell_rights_step,
  delete_blogs,
  delete_sell_rights_step,
  get_blogs,
  get_sell_rights_step,
  update_blogs,
  update_sell_rights_step,
} from "../../Api/Api";

export default function StepByStep() {
  const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [stepId, setStepId] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data, setData] = useState([]);

  const [editedData, setEditedData] = useState({
    stepId: "",
    icon: "",
    descriptions: "",
    stepNo: "",
    names: "",
  });

  const [newData, setNewData] = useState({
    stepId: "",
    icon: "",
    descriptions: "",
    stepNo: "",
    names: "",
  });

  const [newImage, setNewImage] = useState(null);

  const handleEditClick = (id) => {
    setStepId(id);
    setEditMode(true);
    getParticularData(id);
  };

  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedImage(newImage);
  };

  const getAllData = () => {
    const sendData = new FormData();
    sendData.append("stepId", "");
    sendData.append("fusionUserId", 1);
    axios({
      method: "POST",
      url: get_sell_rights_step,
      data: sendData,
      headers: {
        "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
      },
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setData(res.data.data);
          setNewData({
            stepId: "",
            icon: "",
            descriptions: "",
            stepNo: "",
            names: "",
          });
        } else {
          setOpen(true);
          setStatus(false);
          setColor(false);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  const getParticularData = (id) => {
    if (id !== "" && id !== undefined) {
      const sendData = new FormData();
      sendData.append("stepId", id);
      sendData.append("fusionUserId", 1);
      axios({
        method: "POST",
        url: get_sell_rights_step,
        data: sendData,
        headers: {
          "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
        },
      })
        .then((res) => {
          console.log("res", res);
          if (!res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            setEditedData({
              stepId: res.data.data.stepId,
              icon: res.data.data.icon,
              names: res.data.data.names,
              stepNo: res.data.data.stepNo,
              descriptions: res.data.data.descriptions,
            });
          } else {
            setOpen(true);
            setStatus(false);
            setColor(false);
            setMessage(res.data.message);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
  };

  useEffect(() => {
    getParticularData();
  }, [stepId]);

  const handleClick = () => {
    const serverData = new FormData();
    serverData.append("stepId", editedData.stepId);
    serverData.append("stepNo", editedData.stepNo);
    serverData.append("names", editedData.names);
    serverData.append("icon", selectedImage);
    serverData.append("descriptions", editedData.descriptions);
    serverData.append("fusionUserId", 1);
    axios({
      method: "POST",
      url: update_sell_rights_step,
      data: serverData,
      headers: {
        "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
      },
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setStepId("");
          getAllData();
          setSelectedImage(null);
          setEditMode(false);
        } else {
          setOpen(true);
          setStatus(false);
          setColor(false);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Cancel = () => {
    setSelectedImage(null);
    setEditMode(false);
  };

  const DeleteData = (stepId) => {
    if (stepId !== "") {
      const sendData = new FormData();
      sendData.append("stepId", stepId);
      sendData.append("fusionUserId", 1);
      axios({
        method: "POST",
        url: delete_sell_rights_step,
        data: sendData,
        headers: {
          "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
        },
      })
        .then((res) => {
          if (!res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            getAllData();
          } else {
            setOpen(true);
            setStatus(false);
            setColor(false);
            setMessage(res.data.message);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
  };

  const AddNew = () => {
    if (
      newData.names !== "" &&
      newData.descriptions !== "" &&
      newImage !== null
    ) {
      const serverData = new FormData();
      serverData.append("stepNo", newData.stepNo);
      serverData.append("names", newData.names);
      serverData.append("icon", newImage);
      serverData.append("descriptions", newData.descriptions);
      serverData.append("fusionUserId", 1);
      axios({
        method: "POST",
        url: add_sell_rights_step,
        data: serverData,
        headers: {
          "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
        },
      })
        .then((res) => {
          if (!res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            setNewImage(null);
            document.getElementById("newImage").value = "";
            getAllData();
          } else {
            setOpen(true);
            setStatus(false);
            setColor(false);
            setMessage(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setOpen(true);
      setStatus(false);
      setColor(false);
      setMessage("Fill all the Fields");
    }
  };

  const maxLength = 300; // Set the maximum length you want to display

  // Function to truncate the text
  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };

  return (
    <Box>
      <SnackBar
        open={open}
        setOpen={setOpen}
        status={status}
        color={color}
        message={message}
      />

      <Container>
        <Grid container spacing={2} mb={2}>
          {data &&
            data.map((item) => (
              <Grid item xs={12} sm={6} md={6} lg={6} key={item.stepId}>
                {editMode && stepId === item.stepId ? (
                  <Stack spacing={2}>
                    <TextField
                      label="Step No"
                      fullWidth
                      variant="outlined"
                      value={editedData.stepNo}
                      onChange={(e) =>
                        setEditedData({
                          ...editedData,
                          stepNo: e.target.value,
                        })
                      }
                    />

                    <TextField
                      label="Names"
                      fullWidth
                      variant="outlined"
                      value={editedData.names}
                      multiline
                      rows={5}
                      onChange={(e) =>
                        setEditedData({
                          ...editedData,
                          names: e.target.value,
                        })
                      }
                    />
                    <TextField
                      label="Descriptions"
                      fullWidth
                      variant="outlined"
                      value={editedData.descriptions}
                      multiline
                      rows={5}
                      onChange={(e) =>
                        setEditedData({
                          ...editedData,
                          descriptions: e.target.value,
                        })
                      }
                    />

                    <TextField
                      type="file"
                      onChange={handleImageChange}
                      inputProps={{ accept: "image/*" }}
                      sx={{ marginTop: 2 }}
                    />
                    <Box display="flex" flexDirection="row">
                      <Button onClick={handleClick}>Update</Button>
                      <Button onClick={Cancel} color="error">
                        Cancel
                      </Button>
                    </Box>
                  </Stack>
                ) : (
                  <Stack>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignContent="center"
                    >
                      <img
                        src={item.icon}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                      <Box display="flex" flexDirection="column" p={1}>
                        <IconButton
                          onClick={() => handleEditClick(item.stepId)}
                          color="primary"
                          sx={{ marginTop: 1 }}
                        >
                          <EditIcon sx={{ verticalAlign: "middle" }} />
                        </IconButton>

                        <IconButton
                          onClick={() => DeleteData(item.stepId)}
                          color="error"
                          sx={{ marginTop: 1 }}
                        >
                          <DeleteIcon sx={{ verticalAlign: "middle" }} />
                        </IconButton>
                      </Box>
                    </Box>
                    <Stack spacing={1} textAlign="left" mt={1}>
                      <Typography variant="subtitle2">
                        <strong>Step No: </strong> {item.stepNo}
                      </Typography>
                      <Typography variant="subtitle2">
                        <strong> Names: </strong>
                        {item.names}
                      </Typography>
                      <Typography variant="subtitle2">
                        <strong> Descriptions: </strong>
                        {truncateText(item.descriptions, maxLength)}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Grid>
            ))}
        </Grid>

        {/* NEW Step */}

        <Divider />

        <Box mt={5} mb={2}>
          <Heading title={"Add New Step"} />
        </Box>

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
          >
            <TextField
              label="step No"
              fullWidth
              variant="outlined"
              value={newData.stepNo}
              onChange={(e) =>
                setNewData({ ...newData, stepNo: e.target.value })
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
          >
            <TextField
              label="Names"
              fullWidth
              variant="outlined"
              value={newData.names}
              multiline
              rows={5}
              onChange={(e) =>
                setNewData({ ...newData, names: e.target.value })
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
          >
            <TextField
              label="Descriptions"
              fullWidth
              variant="outlined"
              value={newData.descriptions}
              multiline
              rows={5}
              onChange={(e) =>
                setNewData({ ...newData, descriptions: e.target.value })
              }
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
          >
            <TextField
              type="file"
              size="small"
              fullWidth
              id="newImage"
              onChange={(e) => setNewImage(e.target.files[0])}
              inputProps={{ accept: "image/*" }}
              sx={{ marginTop: 2 }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            display="flex"
            justifyContent="start"
          >
            <Box display="flex" flexDirection="row">
              <Button variant="contained" onClick={AddNew}>
                Generate
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import {
  Grid,
  Box ,  Typography , Button, Card,
  TablePagination,
  Stack
} from "@mui/material";
import axios from "axios";
import { get_active_movies } from "../../Api/Api";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {useNavigate } from 'react-router-dom';
import Heading from "../../components/Heading/Heading";
import Nodata from "../../components/Nodata";
import Spinner from "../../components/Spinner/Spinner";

export default function MarketPlace() {


  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);
  const navigate = useNavigate();
  const [loading , setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [totalPages, setTotalPages] = useState(0); 

  const getAllData = (pageNumber) => {
    setLoading(true);
    const sendData = new FormData()
    sendData.append('movieId', '');
    sendData.append('activeMovieId', '');
    sendData.append('fusionUserId', 1);
    sendData.append('rightsId', '');
    sendData.append('sellerId', '');
    sendData.append('noOfData', rowsPerPage);
    sendData.append('pageNo', pageNumber + 1);
    axios({
      method: 'POST',
      url: get_active_movies,
      data:sendData,
      headers: {
        'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
    }
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
        setTotalPages(res.data.totalCount)
        setLoading(false);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
        setLoading(false);
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
      setLoading(false);
    });
  }

  useEffect(() => {
    getAllData(page);
  }, [page , rowsPerPage]);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };





  return (
    <Box p={3}>
      <Box my={1} mb={2}>
        <Heading title={'MarketPlace'} />
      </Box>

      <>
      {loading ? 
      <div style={{display:'flex', justifyContent:'center', alignContent:'center', alignItems:'center', paddingTop:50}}>
      <Spinner />
      </div>
      :

    <Grid container spacing={2}>
    
    {
      data?.length !== 0 ?  data?.map((i)=>{
        return(
          <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ maxWidth: 350 ,boxShadow:5 , height:'100%' , display:'flex' , flexDirection:'column', justifyContent:'space-between' }}>
          <CardMedia
          component="img"
          alt="green iguana"
          height="200"
          image={i.moviePoster}
        />
        <CardContent>
          <Box display='flex' flexDirection='column' gap={1.5}>
          <Box display='flex' flexDirection='row' gap={1}>
          <Typography variant="subtitle2"><strong>Production House: </strong>{i.productionName}</Typography>
          </Box>
          <Box display='flex' justifyContent='start' textAlign='left' flexDirection='column' gap={1}>
          <Typography variant="subtitle2" color='#101010'>
          <strong>Movie: </strong> {i.movieTitle}
          </Typography>
          <Typography variant="subtitle2" color='#101010'><strong>Rights: </strong>{i.movieRights}</Typography>
          <Typography variant="subtitle2"color='#101010'><strong>Price: </strong>{i.price}</Typography>
          </Box>
          </Box>
        </CardContent>
        <Stack p={2} spacing={1}>
        <Box>
         <Button 
         fullWidth  
         onClick={()=>navigate('marketdetail' , {state:{data:i}})}
         variant="contained"
          sx={{bgcolor:'#FFC61A',
           fontWeight:600 , color:'#46344E', 
           ':hover':{color:'#46344E',bgcolor:'#FFC61A'  }}}
          >UNLIST FROM SALE
          </Button>  
        </Box>
        <Box>
         <Button 
         fullWidth 
         variant="contained" 
         color='primary'
         onClick={()=>navigate('/app/uploads/updateuploads' , {state:{MovieId: i.movieId}})}
         sx={{fontWeight:600 }}>
          UPDATE
          </Button>  
        </Box>
        </Stack>
         </Card>
          </Grid>
        )
      })
      :
      <Grid item xs={12}>
        <Box p={3}>
            <Nodata />
        </Box>
      </Grid>
    }

<Grid item xs={12}>
        {
        data?.length !== 0  &&
        <Box mt={3} display="flex" justifyContent="end">
      <TablePagination
      component="div"
      count={totalPages}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
        </Box>
        }
      </Grid>


    </Grid>
}
</>
</Box>
  )
}

// solana-utils.js

import { Connection, Keypair, LAMPORTS_PER_SOL, SystemProgram, Transaction, clusterApiUrl, sendAndConfirmTransaction , PublicKey} from "@solana/web3.js";
import {  BaseWalletAdapter, WalletReadyState} from '@solana/wallet-adapter-base';
import { decode } from 'bs58';


export const createAccount = async (connection, signTransaction) => {
  const keypair = Keypair.generate();
  const transaction = new Transaction().add(
    SystemProgram.createAccount({
      fromPubkey: keypair.publicKey,
      newAccountPubkey: keypair.publicKey,
      lamports: 1000000, // Set an initial balance as needed
      space: 0,
      programId: process.env.REACT_APP_PROGRAMID,
    })
  );

  await sendAndConfirmTransaction(connection, transaction, [keypair], {
    commitment: "singleGossip",
    preflightCommitment: "singleGossip",
  });

  return keypair;
};



export const getBalance = async (connection, publicKey) => {
  const lamports = await connection.getBalance(publicKey).catch((err) => {
    console.error(`Error: ${err}`);
  });

  return lamports / LAMPORTS_PER_SOL;
};



export const useConnection = () => {
  // You can customize the connection options based on your requirements
  const endpoint = "https://api.devnet.solana.com";
  const connection = new Connection(endpoint, "singleGossip");
  return connection;
};


export const createConnection = () => {
  return new Connection(clusterApiUrl("devnet"));
};

export const getRecentBlockhash = async () => {
  const connection = new Connection(clusterApiUrl('devnet')); // Initialize connection
  const { blockhash } = await connection.getRecentBlockhash(); // Fetch recent blockhash
  return blockhash;
};



export const inAppWalletAdapter = {
  name: "InAppWallet",
  adapter: BaseWalletAdapter,
  readyState: WalletReadyState,
  publicKey: new PublicKey(process.env.REACT_APP_PUBLICKEY), // Use the provided public key
  keypair: null,

  signTransaction: async (transaction) => {
    const keypair = await inAppWalletAdapter.getKeyPair();
    transaction.partialSign(keypair);
    return transaction;
  },  

  getKeyPair: async () => {
    // Generate keypair if not already generated
    if (!inAppWalletAdapter.keypair) {
      const privateKey = process.env.REACT_APP_PRIVATEKEY;
      inAppWalletAdapter.keypair = Keypair.fromSecretKey(decode(privateKey));
    }
    return inAppWalletAdapter.keypair;
  },
  getPublicKey: async () => {
    return inAppWalletAdapter.publicKey;
  },
  connect: async () => {
    // Implement connect logic if needed
  },
  disconnect: async () => {
    // Implement disconnect logic if needed
  },
  on: (event, callback) => {
    // Implement event listener logic if needed
  },
  off: (event, callback) => {
    // Implement event removal logic if needed
  },
};


export const encodeBase64 = (uint8Array) => {
  return btoa(String.fromCharCode.apply(null, uint8Array));
};

export const decodeBase64 = (base64String) => {
  const binaryString = atob(base64String);
  const uint8Array = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; ++i) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }
  return uint8Array;
};

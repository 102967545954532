import React, { useEffect, useState } from 'react'
import { Container, Grid, Button, Box, Typography, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Autocomplete, } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { useWallet } from '@solana/wallet-adapter-react';
import {
    clusterApiUrl,
    Keypair,
    PublicKey,
    SystemProgram,
    Transaction,
    Connection
} from '@solana/web3.js';
import { decode } from 'bs58';
import CustomTextField from '../AccountCreation/CustomTextField';
import { add_active_movies } from '../../Api/Api';
import { useNavigate } from 'react-router-dom';
import { inAppWalletAdapter, useConnection } from '../../Solana.utils';
import { LoadingButton } from '@mui/lab';


export default function ListAssetDialog({ 
     setMessage, 
     setOpen, 
     setStatus, 
     setColor, 
     setOpenDialog,
     OpenDialog,
     dialogData, 
     rightsId
 }) {


  const navigate = useNavigate();
 
  const[EnteredPrice , setEnteredPrice] = useState('');
  const marketPlace = process.env.REACT_APP_MARKET_PLACE_ADDRESS;


  const [publicKey, setPublicKey] = useState(null);
  const [WalletConnected , setWalletConnected] = useState(true);
  const { connect, disconnect, connected } = useWallet();
  const connection = useConnection();
  const [keypair, setKeypair] = useState(null);
 
  const [loading, setLoading] = useState(false);
   const handleApiKey = (apiKeyString) => {
   const publicKey = new PublicKey(apiKeyString);
   setPublicKey(publicKey);
   };
 
   useEffect(() => {
   const apiPublicKey = process.env.REACT_APP_PUBLICKEY;
   handleApiKey(apiPublicKey);
   }, []);
  
    const handleClose = () => {
        setOpenDialog(false);
        setEnteredPrice('');
   
    }




    const listForSale = async () => {
        try {

          setLoading(true)

          const requestData = {
            network: "devnet",
            marketplace_address:marketPlace,
            nft_address: dialogData?.mint,
            price: 0.01,
            seller_wallet: publicKey,
            service_charge: {
              "receiver":process.env.REACT_APP_RECEIVER,
              "amount": 0.01
            }
          };
      
          const axiosConfig = {
            headers: {
              "x-api-key": process.env.REACT_APP_APIKEY,
              'Content-Type': 'application/json',
            },
          };
      
          axios.post("https://api.shyft.to/sol/v1/marketplace/list", requestData, axiosConfig)
            .then(async response => {
              const encodedTransaction = response.data.result.encoded_transaction;
              console.log('encodedTransaction' , encodedTransaction)
              const connection = new Connection(clusterApiUrl("devnet"), 'confirmed');
              const recoveredTransaction = Transaction.from(
                Uint8Array.from(atob(encodedTransaction), c => c.charCodeAt(0))
              );
              const signedTx = await inAppWalletAdapter.signTransaction(recoveredTransaction);
              const confirmTransaction = await connection.sendRawTransaction(signedTx.serialize());          
              return {currency_symbol: response.data.result.currency_symbol, 
              encoded_transaction: response.data.result.encoded_transaction,
              nft_address: response.data.result.nft_address, 
              list_state: response.data.result.list_state,
              confirmTransaction };
            })
            .then(({ currency_symbol, encoded_transaction ,nft_address ,  list_state }) => {
              ListedDigital(currency_symbol, encoded_transaction ,nft_address ,  list_state );
            })
            .catch((error) => {
              console.log('Error:', error);
              setOpen(true);
              setStatus(false);
              setColor(false);
              setLoading(false);
            });
        } catch (error) {
          console.error('Error in listForSale:', error);
          setLoading(false);
        }
      };
    
    
     const ListedDigital = (currency_symbol, encoded_transaction ,nft_address ,  list_state) =>{
      // const publicKeyString = wallet.publicKey.toBase58();
      const sendData = new FormData()
      sendData.append('movieId', dialogData?.movieId);
      sendData.append('rightsId', rightsId);
      sendData.append('sellerAddress', publicKey);
      sendData.append('NFTAddress', nft_address);
      sendData.append('marketPlaceAddress', marketPlace);
      sendData.append('price', EnteredPrice);
      sendData.append('listState', list_state);
      sendData.append('currencySymbol', currency_symbol);
      sendData.append('encodedTransaction', encoded_transaction);
      sendData.append('fusionUserId', 1);
      axios({
        method: 'POST',
        url: add_active_movies,
        data:sendData,
        headers: {
          'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
      }
      }).then(res => {
        if (!res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
          setLoading(false);
          navigate('/app/marketplace');
          handleClose();
        } else {
          setOpen(true)
          setStatus(false)
          setColor(false)
          setMessage(res.data.message)
          setLoading(false);
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
        setLoading(false);
      });
     }
      

    return (
        <Dialog onClose={handleClose} open={OpenDialog} fullWidth>
            <DialogTitle sx={{borderBottom:'1px solid silver'}} p={1}>
               LIST THIS DIGITAL ASSET
            </DialogTitle>
            <DialogContent>
            <Stack p={3} spacing={1}>
            <Typography color='#46344E' fontWeight={600}>Listing Price</Typography>
            <CustomTextField
            value={EnteredPrice}
            onChange={setEnteredPrice}
            validationRegex={/^[0-9]+$/}
            errorMessage={"Enter a Numeric Value"}
            />
            </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}   sx={{width:'20ch'}} variant='outlined' color='error'>CLOSE</Button>

                <LoadingButton
              size="small"
              color='success'
              onClick={listForSale}
              loading={loading}
              sx={{width:'20ch'}}
              loadingPosition="end"
              variant="outlined"
              >
              <span>
              CREATE
              </span>
              </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box,
  Container,
  Stack,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import SnackBar from "../../components/SnackBar";
import Heading from "../../components/Heading/Heading";
import {
  add_deal_image,
  delete_deal_image,
  get_deal_image,
  update_deal_image,
} from "../../Api/Api";

export default function Deals() {
  const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setImageId] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data, setData] = useState([]);

  const [editedData, setEditedData] = useState({
    seller: "",
    title: "",
    genre: "",
    originalLanguage: "",
    rightsLicensed: "",
    duration: "",
    startDate: "",
    endDate: "",
    years: "",
    buyer: "",
    wallet: "",
    images: "",
  });

  const [newData, setNewData] = useState({
    seller: "",
    title: "",
    genre: "",
    originalLanguage: "",
    rightsLicensed: "",
    duration: "",
    startDate: "",
    endDate: "",
    years: "",
    buyer: "",
    wallet: "",
    images: "",
  });

  const [newImage, setNewImage] = useState(null);

  const handleEditClick = (id) => {
    setImageId(id);
    setEditMode(true);
    getParticularData(id);
  };

  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedImage(newImage);
  };

  const getAllData = () => {
    const sendData = new FormData();
    // sendData.append("stepId", "");
    sendData.append("fusionUserId", 1);
    axios({
      method: "POST",
      url: get_deal_image,
      data: sendData,
      headers: {
        "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
      },
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setData(res.data.data);
          setNewData({
            seller: "",
            title: "",
            genre: "",
            originalLanguage: "",
            rightsLicensed: "",
            duration: "",
            startDate: "",
            endDate: "",
            years: "",
            buyer: "",
            wallet: "",
            images: "",
          });
        } else {
          setOpen(true);
          setStatus(false);
          setColor(false);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  const getParticularData = (id) => {
    if (id !== "" && id !== undefined) {
      const sendData = new FormData();
      sendData.append("imageId", id);
      sendData.append("fusionUserId", 1);
      axios({
        method: "POST",
        url: get_deal_image,
        data: sendData,
        headers: {
          "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
        },
      })
        .then((res) => {
          console.log("res", res);
          if (!res.data.error) {
            const agreementPeriod = res.data.data.agreementPeriod;
            const [startdate, enddate] = agreementPeriod.split(" to ");
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            setEditedData({
              seller: res.data.data.seller,
              title: res.data.data.title,
              genre: res.data.data.genre,
              originalLanguage: res.data.data.originalLanguage,
              rightsLicensed: res.data.data.rightsLicensed,
              duration: res.data.data.duration,
              startDate: startdate,
              endDate: enddate,
              years: res.data.data.years,
              buyer: res.data.data.buyer,
              wallet: res.data.data.wallet,
              images: res.data.data.images,
            });
          } else {
            setOpen(true);
            setStatus(false);
            setColor(false);
            setMessage(res.data.message);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
  };

  useEffect(() => {
    getParticularData();
  }, [setImageId]);

  const handleClick = () => {
    const serverData = new FormData();
    serverData.append("imageId", imageId);
    serverData.append("seller", editedData.seller);
    serverData.append("title", editedData.title);
    serverData.append("genre", editedData.genre);
    serverData.append("originalLanguage", editedData.originalLanguage);
    serverData.append("rightsLicensed", editedData.rightsLicensed);
    serverData.append("duration", editedData.duration);
    serverData.append(
      "agreementPeriod",
      `${editedData.startDate} to ${editedData.endDate}`
    );
    serverData.append("years", editedData.years);
    serverData.append("buyer", editedData.buyer);
    serverData.append("wallet", editedData.wallet);
    serverData.append("images", newImage);
    serverData.append("fusionUserId", 1);
    axios({
      method: "POST",
      url: update_deal_image,
      data: serverData,
      headers: {
        "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
      },
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setImageId("");
          getAllData();
          setSelectedImage(null);
          setEditMode(false);
        } else {
          setOpen(true);
          setStatus(false);
          setColor(false);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Cancel = () => {
    setSelectedImage(null);
    setEditMode(false);
  };

  const DeleteData = (imageId) => {
    if (imageId !== "") {
      const sendData = new FormData();
      sendData.append("imageId", imageId);
      sendData.append("fusionUserId", 1);
      axios({
        method: "POST",
        url: delete_deal_image,
        data: sendData,
        headers: {
          "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
        },
      })
        .then((res) => {
          if (!res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            getAllData();
          } else {
            setOpen(true);
            setStatus(false);
            setColor(false);
            setMessage(res.data.message);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
  };

  const AddNew = () => {
    if (newData.seller !== "" && newData.genre !== "" && newImage !== null) {
      const serverData = new FormData();
      serverData.append("seller", newData.seller);
      serverData.append("title", newData.title);
      serverData.append("genre", newData.genre);
      serverData.append("originalLanguage", newData.originalLanguage);
      serverData.append("rightsLicensed", newData.rightsLicensed);
      serverData.append("duration", newData.duration);
      serverData.append(
        "agreementPeriod",
        `${newData.startDate} to ${newData.endDate}`
      );
      serverData.append("years", newData.years);
      serverData.append("buyer", newData.buyer);
      serverData.append("wallet", newData.wallet);
      serverData.append("images", newImage);
      serverData.append("fusionUserId", 1);
      axios({
        method: "POST",
        url: add_deal_image,
        data: serverData,
        headers: {
          "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
        },
      })
        .then((res) => {
          if (!res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            setNewImage(null);
            document.getElementById("newImage").value = "";
            getAllData();
          } else {
            setOpen(true);
            setStatus(false);
            setColor(false);
            setMessage(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setOpen(true);
      setStatus(false);
      setColor(false);
      setMessage("Fill all the Fields");
    }
  };

  const maxLength = 300; // Set the maximum length you want to display

  // Function to truncate the text
  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };

  return (
    <Box>
      <SnackBar
        open={open}
        setOpen={setOpen}
        status={status}
        color={color}
        message={message}
      />

      <Container>
        <Grid container spacing={2} mb={2}>
          {data &&
            data.map((item) => (
              <Grid item xs={12} sm={6} md={6} lg={6} key={item.imageId}>
                {editMode && imageId === item.imageId ? (
                  <Stack spacing={2}>
                    <TextField
                      label="seller"
                      fullWidth
                      variant="outlined"
                      value={editedData.seller}
                      onChange={(e) =>
                        setEditedData({ ...editedData, seller: e.target.value })
                      }
                    />
                    <TextField
                      label="title"
                      fullWidth
                      variant="outlined"
                      value={editedData.title}
                      onChange={(e) =>
                        setEditedData({ ...editedData, title: e.target.value })
                      }
                    />
                    <TextField
                      label="Genre"
                      fullWidth
                      variant="outlined"
                      value={editedData.genre}
                      onChange={(e) =>
                        setEditedData({ ...editedData, genre: e.target.value })
                      }
                    />
                    <TextField
                      label="originalLanguage"
                      fullWidth
                      variant="outlined"
                      value={editedData.originalLanguage}
                      onChange={(e) =>
                        setEditedData({
                          ...editedData,
                          originalLanguage: e.target.value,
                        })
                      }
                    />
                    <TextField
                      label="RightsLicensed"
                      fullWidth
                      variant="outlined"
                      value={editedData.rightsLicensed}
                      onChange={(e) =>
                        setEditedData({
                          ...editedData,
                          rightsLicensed: e.target.value,
                        })
                      }
                    />
                    <TextField
                      label="Duration"
                      fullWidth
                      variant="outlined"
                      value={editedData.duration}
                      onChange={(e) =>
                        setEditedData({
                          ...editedData,
                          duration: e.target.value,
                        })
                      }
                    />

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      display="flex"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            label="agreement start"
                            fullWidth
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            value={editedData.startDate}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                startDate: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="agreement End"
                            fullWidth
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            value={editedData.endDate}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                endDate: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <TextField
                      label="Years"
                      fullWidth
                      variant="outlined"
                      value={editedData.years}
                      onChange={(e) =>
                        setEditedData({ ...editedData, years: e.target.value })
                      }
                    />
                    <TextField
                      label="Buyer"
                      fullWidth
                      variant="outlined"
                      value={editedData.buyer}
                      onChange={(e) =>
                        setEditedData({ ...editedData, buyer: e.target.value })
                      }
                    />
                    <TextField
                      label="wallet"
                      fullWidth
                      variant="outlined"
                      value={editedData.wallet}
                      onChange={(e) =>
                        setEditedData({ ...editedData, wallet: e.target.value })
                      }
                    />

                    <TextField
                      type="file"
                      onChange={handleImageChange}
                      inputProps={{ accept: "image/*" }}
                      sx={{ marginTop: 2 }}
                    />
                    <Box display="flex" flexDirection="row">
                      <Button onClick={handleClick}>Update</Button>
                      <Button onClick={Cancel} color="error">
                        Cancel
                      </Button>
                    </Box>
                  </Stack>
                ) : (
                  <Stack>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignContent="center"
                    >
                      <img
                        src={item.images}
                        style={{
                          width: 200,
                          height: 200,
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                      <Box display="flex" flexDirection="column" p={1}>
                        <IconButton
                          onClick={() => handleEditClick(item.imageId)}
                          color="primary"
                          sx={{ marginTop: 1 }}
                        >
                          <EditIcon sx={{ verticalAlign: "middle" }} />
                        </IconButton>

                        <IconButton
                          onClick={() => DeleteData(item.imageId)}
                          color="error"
                          sx={{ marginTop: 1 }}
                        >
                          <DeleteIcon sx={{ verticalAlign: "middle" }} />
                        </IconButton>
                      </Box>
                    </Box>
                    <Stack spacing={1} textAlign="left" mt={1}>
                      <Typography variant="subtitle2">
                        <strong>Seller: </strong> {item.seller}
                      </Typography>
                      <Typography variant="subtitle2">
                        <strong> Title: </strong>
                        {item.title}
                      </Typography>
                      <Typography variant="subtitle2">
                        <strong> Genre: </strong>
                        {item.genre}
                      </Typography>
                      <Typography variant="subtitle2">
                        <strong> OriginalLanguage: </strong>
                        {item.originalLanguage}
                      </Typography>
                      <Typography variant="subtitle2">
                        <strong> RightsLicensed: </strong>
                        {item.rightsLicensed}
                      </Typography>
                      <Typography variant="subtitle2">
                        <strong> Duration: </strong>
                        {item.duration}
                      </Typography>
                      <Typography variant="subtitle2">
                        <strong> AgreementPeriod: </strong>
                        {item.agreementPeriod}
                      </Typography>
                      <Typography variant="subtitle2">
                        <strong> Years: </strong>
                        {item.years}
                      </Typography>
                      <Typography variant="subtitle2">
                        <strong> Buyer: </strong>
                        {item.buyer}
                      </Typography>
                      <Typography variant="subtitle2">
                        <strong> Wallet: </strong>
                        {item.wallet}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Grid>
            ))}
        </Grid>

        {/* NEW Step */}

        <Divider />

        <Box mt={5} mb={2}>
          <Heading title={"Add New Deal"} />
        </Box>

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
          >
            <TextField
              label="seller"
              fullWidth
              variant="outlined"
              value={newData.seller}
              onChange={(e) =>
                setNewData({ ...newData, seller: e.target.value })
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
          >
            <TextField
              label="Title"
              fullWidth
              variant="outlined"
              value={newData.title}
              multiline
              onChange={(e) =>
                setNewData({ ...newData, title: e.target.value })
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
          >
            <TextField
              label="genre"
              fullWidth
              variant="outlined"
              value={newData.genre}
              multiline
              onChange={(e) =>
                setNewData({ ...newData, genre: e.target.value })
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
          >
            <TextField
              label="originalLanguage"
              fullWidth
              variant="outlined"
              value={newData.originalLanguage}
              multiline
              onChange={(e) =>
                setNewData({ ...newData, originalLanguage: e.target.value })
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
          >
            <TextField
              label="RightsLicensed"
              fullWidth
              variant="outlined"
              value={newData.rightsLicensed}
              multiline
              onChange={(e) =>
                setNewData({ ...newData, rightsLicensed: e.target.value })
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
          >
            <TextField
              label="Duration"
              fullWidth
              variant="outlined"
              value={newData.duration}
              multiline
              onChange={(e) =>
                setNewData({ ...newData, duration: e.target.value })
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
            alignItems="center"
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="agreement start"
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={newData.startDate}
                  onChange={(e) =>
                    setNewData({ ...newData, startDate: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="agreement End"
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={newData.endDate}
                  onChange={(e) =>
                    setNewData({ ...newData, endDate: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
          >
            <TextField
              label="Years"
              fullWidth
              variant="outlined"
              value={newData.years}
              multiline
              onChange={(e) =>
                setNewData({ ...newData, years: e.target.value })
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
          >
            <TextField
              label="Buyer"
              fullWidth
              variant="outlined"
              value={newData.buyer}
              multiline
              onChange={(e) =>
                setNewData({ ...newData, buyer: e.target.value })
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
          >
            <TextField
              label="Wallet"
              fullWidth
              variant="outlined"
              value={newData.wallet}
              multiline
              onChange={(e) =>
                setNewData({ ...newData, wallet: e.target.value })
              }
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
          >
            <TextField
              type="file"
              size="small"
              fullWidth
              id="newImage"
              onChange={(e) => setNewImage(e.target.files[0])}
              inputProps={{ accept: "image/*" }}
              sx={{ marginTop: 2 }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            display="flex"
            justifyContent="start"
          >
            <Box display="flex" flexDirection="row">
              <Button variant="contained" onClick={AddNew}>
                Generate
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

import { BaseUrl } from "./BaseUrl";

const apibaseUrl = BaseUrl.UAT;

const methodGet = "GET";
const methodPost = "POST";

const get_carousel_image = `${apibaseUrl}get_carousel_image`;

const get_deal_image = `${apibaseUrl}get_deal_image`;

const get_brand_image = `${apibaseUrl}get_brand_image`;

const get_movies_rights = `${apibaseUrl}get_movies_rights`;

const get_movies_particular_rights = `${apibaseUrl}get_movies_particular_rights`;

const add_movie_details = `${apibaseUrl}add_movie_details`;

const get_movie_details = `${apibaseUrl}get_movie_details`;

const admin_sign_in = `${apibaseUrl}admin_sign_in`;

const add_active_movies = `${apibaseUrl}add_active_movies`;

const get_active_movies = `${apibaseUrl}get_admin_active_movies`;

const add_nft_created_movies = `${apibaseUrl}add_nft_created_movies`;

const get_nft_created_movies = `${apibaseUrl}get_nft_created_movies`;

const inactive_movies = `${apibaseUrl}inactive_movies`;

const get_transaction_movies = `${apibaseUrl}get_transaction_movies`;

const add_buy_movies = `${apibaseUrl}add_buy_movies`;

const get_query = `${apibaseUrl}get_enquiry`;

const get_user_payment_details = `${apibaseUrl}get_user_payment_details`;

const add_wallet_details = `${apibaseUrl}add_wallet_details`;

const get_wallet_details = `${apibaseUrl}get_wallet_details`;

const get_users = `${apibaseUrl}get_users`;

const get_seller = `${apibaseUrl}get_seller`;

const add_movie_details_by_admin = `${apibaseUrl}add_movie_details_by_admin`;

const delete_movie_details = `${apibaseUrl}delete_movie_details`;

const update_movie_details_admin = `${apibaseUrl}update_movie_details_admin`;

const get_genres = `${apibaseUrl}get_genres`;

const get_languages = `${apibaseUrl}get_languages`;

const get_blogs = `${apibaseUrl}get_blogs`;

const update_blogs = `${apibaseUrl}update_blogs`;

const add_blogs = `${apibaseUrl}add_blogs`;

const delete_blogs = `${apibaseUrl}delete_blogs`;

const get_stack_cards = `${apibaseUrl}get_stack_cards`;

const add_stack_cards = `${apibaseUrl}add_stack_cards`;

const update_stack_cards = `${apibaseUrl}update_stack_cards`;

const delete_stack_cards = `${apibaseUrl}delete_stack_cards`;

const get_allaince_creds = `${apibaseUrl}get_allaince_creds`;

const add_allaince_creds = `${apibaseUrl}add_allaince_creds`;

const update_allaince_creds = `${apibaseUrl}update_allaince_creds`;

const delete_allaince_creds = `${apibaseUrl}delete_allaince_creds`;

const get_content_buyers = `${apibaseUrl}get_content_buyers`;

const add_content_buyers = `${apibaseUrl}add_content_buyers`;

const update_content_buyers = `${apibaseUrl}update_content_buyers`;

const delete_content_buyers = `${apibaseUrl}delete_content_buyers`;

const add_sell_rights_step = `${apibaseUrl}add_sell_rights_step`;

const get_sell_rights_step = `${apibaseUrl}get_sell_rights_step`;

const update_sell_rights_step = `${apibaseUrl}update_sell_rights_step`;

const delete_sell_rights_step = `${apibaseUrl}delete_sell_rights_step`;

const get_media_mentions = `${apibaseUrl}get_media_mentions`;

const add_media_mentions = `${apibaseUrl}add_media_mentions`;

const update_media_mentions = `${apibaseUrl}update_media_mentions`;

const delete_media_mentions = `${apibaseUrl}delete_media_mentions`;

const get_endorsements = `${apibaseUrl}get_endorsements`;

const add_endorsements = `${apibaseUrl}add_endorsements`;

const update_endorsements = `${apibaseUrl}update_endorsements`;

const delete_endorsements = `${apibaseUrl}delete_endorsements`;

const add_deal_image = `${apibaseUrl}add_deal_image`;

const update_deal_image = `${apibaseUrl}update_deal_image`;

const delete_deal_image = `${apibaseUrl}delete_deal_image`;

const add_faq = `${apibaseUrl}add_faq`;

const get_faq = `${apibaseUrl}get_faq`;

const delete_faq = `${apibaseUrl}delete_faq`;

const update_faq = `${apibaseUrl}update_faq`;

const add_video_link = `${apibaseUrl}add_video_link`;

const get_video_link = `${apibaseUrl}get_video_link`;

const update_video_link = `${apibaseUrl}update_video_link`;

const delete_video_link = `${apibaseUrl}delete_video_link`;

export {
  methodGet,
  add_video_link,
  get_video_link,
  update_video_link,
  delete_video_link,
  update_faq,
  delete_faq,
  get_faq,
  add_faq,
  update_deal_image,
  delete_deal_image,
  add_deal_image,
  get_endorsements,
  add_endorsements,
  update_endorsements,
  delete_endorsements,
  delete_media_mentions,
  update_media_mentions,
  add_media_mentions,
  get_media_mentions,
  delete_sell_rights_step,
  update_sell_rights_step,
  get_sell_rights_step,
  add_sell_rights_step,
  delete_stack_cards,
  update_stack_cards,
  add_stack_cards,
  get_stack_cards,
  delete_allaince_creds,
  update_allaince_creds,
  add_allaince_creds,
  get_allaince_creds,
  delete_content_buyers,
  update_content_buyers,
  add_content_buyers,
  get_content_buyers,
  get_genres,
  get_languages,
  add_blogs,
  delete_blogs,
  update_blogs,
  get_blogs,
  methodPost,
  update_movie_details_admin,
  add_active_movies,
  delete_movie_details,
  add_movie_details_by_admin,
  get_seller,
  get_users,
  get_wallet_details,
  add_wallet_details,
  get_user_payment_details,
  get_query,
  add_buy_movies,
  get_transaction_movies,
  get_nft_created_movies,
  inactive_movies,
  add_nft_created_movies,
  get_active_movies,
  admin_sign_in,
  add_movie_details,
  get_movie_details,
  apibaseUrl,
  get_movies_particular_rights,
  get_movies_rights,
  get_carousel_image,
  get_deal_image,
  get_brand_image,
};

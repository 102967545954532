import React, {useState, useEffect} from 'react'
import {  Box, Button, Card, Container, Grid,  Typography, TextField, Autocomplete, Checkbox } from '@mui/material'
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTextField from '../AccountCreation/CustomTextField';
import axios from 'axios';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { add_movie_details, add_movie_details_by_admin, get_movie_details, get_movies_particular_rights, get_movies_rights, get_seller, get_users, methodPost, update_movie_details_admin } from '../../Api/Api';
import { appendData, genreOptions, languageOptions } from '../../Variables/Variables';
import SnackBar from '../../components/SnackBar';
import Heading from '../../components/Heading/Heading';

export default function UpdateUpload({}) {

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
        };
    
        function TabPanel(props) {
        const { children, value, index, ...other } = props;
    
        return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
        >
        {value === index && (
          <Box sx={{ p: 2 }}>
              <Typography>{children}</Typography>
          </Box>
        )}
        </div>
        );
        }

        const handleNext = () => {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        };
      
        const handleBack = () => {
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        };
      
        const handleReset = () => {
          setActiveStep(0);
        };

        const steps = ['Your Details', 'Movie Details', 'Additional Details'];

      const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
      const checkedIcon = <CheckBoxIcon fontSize="small" />;


        const navigate = useNavigate();
        const [activeStep, setActiveStep] = useState(0);
        const [skipped, setSkipped] = useState(new Set());
        const [open, setOpen] = useState(false);
        const [status, setStatus] = useState(false);
        const [color, setColor] = useState(false);
        const [message, setMessage] = useState("");
        const [productionHouse, setProductionHouse] = useState(null);
        const [email, setEmail] =useState(null);
        const [movieTitle, setMovieTitle] = useState('');
        const [censorApplied, setCensorApplied] = useState(null);
        const [censorCertificate, setCensorCertificate] = useState(null);
        const [movieDuration, setMovieDuration] = useState(null);
        const [languages, setLanguages] = useState('');
        const [genre, setGenre] = useState('');
        const [synopsis, setSynopsis] = useState('');
        const [releaseStatus, setReleaseStatus] = useState(null);
        const [iprRegistered , setIprRegistered] = useState(null);
        const [rightsCategoryList, setRightsCategoryList] = useState([]);
        const [rightsCategory, setRightsCategory] = useState('');
        const [moviePoster, setMoviePoster] = useState(null);
        const [trailerLink, setTrailerLink] = useState('');
        const [castDetails, setCastDetails] = useState('');
        const [crewDetails, setCrewDetails] = useState('');
        const [imdb, setImdb] = useState('');
        const [imdbLink, setImdbLink] = useState('');
        const [wikipedia, setWikipedia] = useState('');
        const [wikipediaLink, setWikipediaLink] = useState('');
        const [awardsAndCoverage, setAwardsAndCoverage] = useState('');
        const [errorMsg7, setErrorMsg7] = useState('');
        const [Error7, setError7] = useState(false);

        const [errorMsg8, setErrorMsg8] = useState('');
        const [Error8, setError8] = useState(false);

        const location = useLocation();
        const { MovieId , RightId } = location.state;


        const userId = localStorage.getItem('BazaarAdminUserId');
        const userName = localStorage.getItem('BazaarUserName');
        const [data , setData] = useState([]);
        const [sellerList , setSellerList]= useState([]);
        const [selectedSeller , setSelectedSeller]= useState(null);
        const [acknowledgementChecked, setacknowledgementChecked] = useState(null)
        const [checked, setChecked] = useState(false);
        const [uploadBy , setUploadBy] = useState('')

        const handleCheckboxChange = (event) => {
          if(event.target.checked){
          setChecked(event.target.checked);
          setacknowledgementChecked(1);
          }
          else{
            setChecked(!event.target.checked);
            setacknowledgementChecked(0);
          }
        };

        useEffect(() => {
          const sendData = new FormData()
          sendData.append('fusionUserId', 1);
          axios({
              method: 'POST',
              url: get_movies_rights,
              data:sendData,
              headers: {
                'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
            }
          })
              .then((res) => {
                  if (res.data.error) {
                      setMessage(res.data.message);
                      setOpen(true);
                      setStatus(false);
                      setColor(false);
                  } else {
                      setMessage(res.data.message);
                      setRightsCategoryList(res.data.data);
                      setOpen(true);
                      setStatus(true);
                      setColor(true);
                  }
              })
              .catch((err) => {
                  alert("Oops something went wrong " + err);
              });
      }, []);



    useEffect(() => {
      const sendData = new FormData()
      sendData.append('fusionUserId', 1);
      axios({
          method: 'POST',
          url: get_seller,
          data:sendData,
          headers: {
            'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
        }
      })
          .then((res) => {
              if (res.data.error) {
                  setMessage(res.data.message);
                  setOpen(true);
                  setStatus(false);
                  setColor(false);
              } else {
                  setMessage(res.data.message);
                  setSellerList(res.data.data);
                  setOpen(true);
                  setStatus(true);
                  setColor(true);
              }
          })
          .catch((err) => {
              alert("Oops something went wrong " + err);
          });
  }, []);

    const onSubmit = (e) =>{
      e.preventDefault();
      if(acknowledgementChecked !== null){
        const Rights = rightsCategory ?  rightsCategory?.map((i)=>{
          const obj = {
            "rightsId":i.rightsId,
          }
          return obj;
        }) : [];
  
      const obj= {
        sellerId: uploadBy === 'Seller' ? selectedSeller?.sellerId : '',
        sellerName: uploadBy === 'Seller' ? selectedSeller?.userName : '',
        emailId:uploadBy === 'Seller' ? email : '',
        movieTitle:movieTitle,
        isCensorApplied:censorApplied,
        movieDuration:movieDuration,
        languages:JSON.stringify(languages?.join(', ')),
        movieGenre:JSON.stringify(genre?.join(', ')),
        movieSynopsis:synopsis,
        movieReleaseStatus:releaseStatus,
        isIPRRegistered:iprRegistered,
        movieRights:'',
        particularRights:'',
        movieTrailerLink:trailerLink,
        movieCast:castDetails,
        movieCrew:crewDetails,
        isIMBDListedMovie:imdb,
        IMDBLink:imdbLink,
        isWikipediaListedMovie:wikipedia,
        wikipediaLink:wikipediaLink,
        receivedAwards:awardsAndCoverage,
        censorCertificate:censorCertificate,
        moviePoster:moviePoster,
        fusionUserId:1,
        saveType: "Upload",
        acknowledgement:acknowledgementChecked,
        adminId: uploadBy === 'Admin' ? userId : '',
        movieId:MovieId,
      }

      const lData = appendData(obj);
      axios({
        method: methodPost,
        url: update_movie_details_admin,
        data:lData,
        headers: {
          'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
      }
    })
        .then((res) => {
            if (!res.data.error) {
                setMessage(res.data.message);
                setOpen(true);
                setStatus(true);
                setColor(true);
                navigate(-1)
            } else {
              setMessage(res.data.message);
              setOpen(true);
              setStatus(false);
              setColor(false);
            }
        })
        .catch((err) => {
            alert("Oops something went wrong " + err);
        });
      }
      else{
        setMessage("Please confirm your acknowledgement");
        setOpen(true);
        setStatus(false);
        setColor(false);
      }
    }


    const validateInput = () =>{
      if(email !== ''){
      if (/\S+@\S+\.\S+/.test(email)) {
        setErrorMsg7("");
        setError7(false);
        } else {
        setError7(true);
        setErrorMsg7("Invalid Email Address");
        }
      }
      else{
        setError7(true);
        setErrorMsg7("Email Address Cannot Be Empty");
      }
    }
    const logout = () =>{
      navigate('/');
      localStorage.clear();
    }

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (!file) return; // No file selected
    
      // Validate file size (1MB limit)
      if (file.size > 1024 * 1024) {
        setErrorMsg8('File size exceeds the limit (1MB)');
        setError8(true);
        e.target.value = null; // Clear the file input
        return;
      }
    
      // Validate file type
      const acceptedTypes = ['image/png', 'image/jpeg', 'image/webp'];
      if (!acceptedTypes.includes(file.type)) {
        setErrorMsg8('Only PNG, JPEG, and WEBP files are allowed');
        setError8(true);
        e.target.value = null; // Clear the file input
        return;
      }
    
      // File is valid, do something with it (e.g., setMoviePoster)
      setMoviePoster(file);
      setErrorMsg8('');
      setError8(false);
    };
    

    const getParticularMovie = async () => {
        if(MovieId){
        const sendData = new FormData()
        sendData.append('movieId', MovieId);
        sendData.append('fusionUserId', 1);
        sendData.append('rightsId', '');
        sendData.append('noOfData', 1);
        sendData.append('pageNo', 1);
        axios({
          method: 'POST',
          url: get_movie_details,
          data:sendData,
          headers: {
            'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
        }
        }).then(res => {
          if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setUploadBy(res.data.data.sellerId === 0 ? 'Admin' :'Seller')
          setSelectedSeller(res.data.data.userName === null ? {'userName':res.data.data.userName, 'sellerId':res.data.data.sellerId} : null);
          setProductionHouse(res.data.data.productionName)
          setEmail(res.data.data.userMail)
          setMovieTitle(res.data.data.movieTitle);
          setCensorApplied(res.data.data.isCensorApplied);
          setMovieDuration(res.data.data.movieDuration);
          setLanguages(res.data.data.languages?.split(',').map(item => item.replace(/['"']/g, '').trim()));
          setGenre(res.data.data.movieGenre?.split(',').map(item => item.replace(/['"']/g, '').trim()));
          setSynopsis(res.data.data.movieSynopsis);
          setReleaseStatus(res.data.data.movieReleaseStatus);
          setIprRegistered(res.data.data.isIPRRegistered);
          setRightsCategory(res.data.data.movieRights);
          setTrailerLink(res.data.data.movieTrailerLink);
          setCastDetails(res.data.data.movieCast);
          setCrewDetails(res.data.data.movieCrew);
          setImdb(res.data.data.isIMBDListedMovie);
          setImdbLink(res.data.data.IMDBLink);
          setWikipedia(res.data.data.isWikipediaListedMovie);
          setWikipediaLink(res.data.data.wikipediaLink);
          setAwardsAndCoverage(res.data.data.receivedAwards);
          setacknowledgementChecked(res.data.data.acknowledgement);
          setChecked(res.data.data.acknowledgement === 1 ? true : false)
          }
          else {
            setOpen(true)
            setStatus(false)
            setColor(false)
            setMessage(res.data.message)
          }
        }).catch(err => {
          alert('Oops something went wrong ' + err)
        });
    }
    else{
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage('Something Went Wrong');
        navigate(-1);  
    }
      }
     
      useEffect(()=>{
        getParticularMovie();
      },[MovieId])

      const defaultRightValues = rightsCategory ? rightsCategory  : [];

  return (
    <Box p={2}>
        <Box my={1} mb={2}>
        <Heading title={'Update Movie'} />
        </Box>
      <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
        <form  onSubmit={onSubmit} >
         <Container>
         <Box component={Card} p={4} mt={4} textAlign='left' borderRadius='20px' boxShadow={7}>
         <Grid container spacing={3} >

         <Grid item xs={12} md={6}>
       <Typography color='#46344E' fontWeight={600}>Upload By Admin / Seller *</Typography>
        <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={['Admin', 'Seller']}
        value={uploadBy}
        sx={{
         mt:1
        }}
        onChange={(event, value) =>  setUploadBy(value)}
        size='small'
        renderInput={(params) => <TextField   
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
            }
          }}
        size="small"
        required
        variant="outlined"
        {...params} />
        }
        />
       </Grid>

          {

          uploadBy === 'Seller' &&
          <>
          <Grid item xs={12} md={6}>
          <Typography color='#46344E' fontWeight={600}>Choose Seller *</Typography>
          <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={sellerList}
          value={selectedSeller}
          getOptionLabel={(option)=>option.userName}
          sx={{
          mt:1
          }}
          onChange={(event, value) => {
            if(value){
                console.log(value)
              setSelectedSeller(value)
              setEmail(value.userMail);
              setProductionHouse(value.productionName);
            }
            else{
              setSelectedSeller(null)
              setEmail(null);
              setProductionHouse(null);
            }
          }}
          size='small'
          renderInput={(params) => <TextField   
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "20px",
              }
            }}
          size="small"
          variant="outlined"
          required
          {...params} />
          }
          />
          </Grid>

          <Grid item xs={12} md={6}>
          <Typography color='#46344E' fontWeight={600}>Production House Name *</Typography>
          <TextField
          size="small"
          fullWidth
          InputProps={{
          sx:{borderRadius:'20px' ,  borderColor:'#FAED26', mt:1}
          }}
          required
          variant="outlined"
          autoComplete="off"
          value={productionHouse}
          />
          </Grid>

          <Grid item xs={12} md={6}>
          <Typography color='#46344E' fontWeight={600}>Seller Mail ID *</Typography>
          <TextField
          size="small"
          fullWidth
          required
          InputProps={{
          sx:{borderRadius:'20px' ,  borderColor:'#FAED26', mt:1}
          }}
          variant="outlined"
          autoComplete="off"
          value={email}
          />
          </Grid>
          </>
          }
    

        <Grid item xs={12} md={6}>
        <Typography color='#46344E' fontWeight={600}>Movie title to be Listed? *</Typography>
       <CustomTextField                                            
        value={movieTitle}
        onChange={setMovieTitle}
        validationRegex={/^[A-Za-z0-9\s]+$/}
       errorMessage={"Forbidden character: %<>$'\""}
       required={true}
      />
       </Grid>
 
       <Grid item xs={12} md={6}>
       <Typography color='#46344E' fontWeight={600}>Have you Applied for Censor? *</Typography>
        <Autocomplete
        disablePortal
        id="combo-box-demo"

        options={['Yes, Already Done', 'No, yet to be done']}
        value={censorApplied}
        sx={{
         mt:1
        }}
        onChange={(event, value) =>  setCensorApplied(value)}
        size='small'
        renderInput={(params) => <TextField   
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
            }
          }}
        size="small"
        variant="outlined"
        required
        {...params} />
        }
        />
       </Grid>
 
       <Grid item xs={12} md={6}>
       <Typography color='#46344E' fontWeight={600}>If Yes, Upload your Censor Certificate *</Typography>
       <TextField
        size="small"
        fullWidth
        InputProps={{
        sx:{borderRadius:'20px' ,  borderColor:'#FAED26', mt:1}
      }}
        variant="outlined"
        type="file"
        autoComplete="off"
        onChange={(e)=>setCensorCertificate(e.target.files[0])}
        />
       </Grid>
 
 
       <Grid item xs={12} md={6}>
       <Typography color='#46344E' fontWeight={600}>Your movie Duration? (mention in minutes - number) *</Typography>
      <CustomTextField                                            
      value={movieDuration}
      onChange={setMovieDuration}
      validationRegex={/^[0-9]+$/}
      errorMessage={"Enter a Numeric Value"}
      required={true}
      />
       </Grid>
 
 
       <Grid item xs={12} md={6}>
       <Typography color='#46344E' fontWeight={600}>Available Languages *</Typography>
       <Autocomplete
       multiple
      id="checkboxes-tags-demo"
      options={languageOptions}
      onChange={(e , value)=>setLanguages(value)}
      disableCloseOnSelect
      value={languages ? languages : []}
      sx={{
        mt:1
       }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params}
        size='small'
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "20px",
          }
        }}
        />
      )}
    />
       </Grid>
 
       <Grid item xs={12} md={6}>
       <Typography color='#46344E' fontWeight={600}>Genre *</Typography>
       <Autocomplete
       multiple
      id="checkboxes-tags-demo"
      options={genreOptions}
      onChange={(e , value)=>setGenre(value)}
      disableCloseOnSelect
      value={genre ? genre : []}
      sx={{
        mt:1
       }}
       getOptionLabel={(option)=>option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params}
        size='small'
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "20px",
          }
        }}
        />
      )}
    />
       </Grid>
 
       <Grid item xs={12} md={6}>
       <Typography color='#46344E' fontWeight={600}>Movie Release Status *</Typography>
       <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={['Already Released', 'Ready for Release' , 'Under Production']}
        value={releaseStatus}
        sx={{
         mt:1
        }}
        onChange={(event, value) =>  setReleaseStatus(value)}
        size='small'
        renderInput={(params) => <TextField   
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
            }
          }}
        size="small"
        variant="outlined"
        required
        {...params} />
        }
        />
       </Grid>

       <Grid item xs={12} md={6}>
        <Typography color='#46344E' fontWeight={600}>Upload the Movie Poster *</Typography>
        <TextField
        size="small"
        fullWidth
        InputProps={{
        sx:{borderRadius:'20px' ,  borderColor:'#FAED26', mt:1}
      }}
        variant="outlined"
        type="file"
        autoComplete="off"
        accept=".png, .jpeg, .jpg, .webp"
        helperText={errorMsg8}
        error={Error8}
        onChange={handleFileChange}
        />
        </Grid>

       <Grid item xs={12} md={6} display='flex' flexDirection='column' gap={1}>
       <Typography color='#46344E' fontWeight={600}>Does your movie registered under IPR Act? *</Typography>
       <Typography variant='caption'>IPR provide certain exclusive rights to the inventors/creators of the movie, in order to enable them to reap commercial benefits from their creative efforts or reputation. Trademark / Copyright</Typography>
       <Autocomplete
        disablePortal
        id="combo-box-demo"

        options={['YES', 'NO']}
        value={iprRegistered}
        sx={{
         mt:1
        }}
        onChange={(event, value) =>  setIprRegistered(value)}
        size='small'
        renderInput={(params) => <TextField   
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
            }
          }}
        size="small"
        variant="outlined"
        required
        {...params} />
        }
        />
       </Grid>

      
       {/* <Grid item xs={12} md={6} display='flex' flexDirection='column' gap={1}>
       <Typography color='#46344E' fontWeight={600}>Agency/Distributor/ Aggregator Name</Typography>
       <Typography variant='caption' width='70%'>If you are an Agent / appointed an Agent to handle the movie transactions, kindly mention it</Typography>
      <CustomTextField                                            
      value={agencyName}
      onChange={setAgencyName}
      validationRegex={/^[A-Za-z0-9\s\-.,#&'"/]+$/}
      errorMessage={"Forbidden character: %<>$'\""}
      />
       </Grid> */}
 
       {/* <Grid item xs={12} md={6}>
       <Typography color='#46344E' fontWeight={600}>Agency/Distributor/Aggregator Contact Number?</Typography>
      <CustomTextField                                            
      value={agencyContactNumber}
      onChange={setAgencyContactNumber}
      validationRegex={/^[A-Za-z0-9\s\-.,#&'"/]+$/}
      errorMessage={"Forbidden character: %<>$'\""}
      />
       </Grid> */}
    

       {/* <Grid item xs={12} md={6}>
         <Typography color='#46344E' fontWeight={600}>Choose the rights among the list which you wish to trade *</Typography>
         <Autocomplete
       multiple
      id="checkboxes-tags-demo"
      options={rightsCategoryList}
      onChange={(e , value)=>setRightsCategory(value)}
      disableCloseOnSelect
      value={defaultRightValues}
      sx={{
        mt:1
       }}
       getOptionLabel={(option) => option.movieRights}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.movieRights}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params}
        size='small'
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "20px",
          }
        }}
        />
      )}
    />
        </Grid> */}

        {/* <Grid item xs={12} md={6}>
         <Typography color='#46344E' fontWeight={600}>Choose the rights from the Selected Category *</Typography>
         <Autocomplete
       multiple
      id="checkboxes-tags-demo"
      options={rightsSubCategoryList}
      onChange={(e , value)=>setRightsSubCategory(value)}
      disableCloseOnSelect
      sx={{
        mt:1
       }}
       getOptionLabel={(option)=>option.particularRights}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.particularRights}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params}
        size='small'
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "20px",
          }
        }}
        />
      )}
    />
        </Grid> */}
  

  
        <Grid item xs={12} md={6}>
        <Typography color='#46344E' fontWeight={600}>Share the movie Trailer / Promo Link</Typography>
        <CustomTextField                                            
        value={trailerLink}
        onChange={setTrailerLink}
        validationRegex={``}
        required={false}
        errorMessage={"Forbidden character: %<>$'\""}
        />
        </Grid>
  
        <Grid item xs={12} md={6}>
        <Typography color='#46344E' fontWeight={600}>Is your Movie listed in IMDB? *</Typography>
        <Autocomplete
        disablePortal
        id="combo-box-demo"

        options={['YES', 'NO']}
        value={imdb}
        sx={{
         mt:1
        }}
        onChange={(event, value) =>  setImdb(value)}
        size='small'
        renderInput={(params) => <TextField   
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
            }
          }}
        size="small"
        variant="outlined"
        required
        {...params} />
        }
        />
        </Grid>
  
        <Grid item xs={12} md={6}>
        <Typography color='#46344E' fontWeight={600}>If yes, Mention the IMDB Link</Typography>
       <CustomTextField                                            
      value={imdbLink}
      onChange={setImdbLink}
      required={false}
      validationRegex={``}
      errorMessage={"Forbidden character: %<>$'\""}
      />
        </Grid>
  
        <Grid item xs={12} md={6}>
        <Typography color='#46344E' fontWeight={600}>Is your Movie listed in Wikipedia? *</Typography>
        <Autocomplete
        disablePortal
     
        id="combo-box-demo"
        options={['YES', 'NO']}
        value={wikipedia}
        sx={{
         mt:1
        }}
        onChange={(event, value) =>  setWikipedia(value)}
        size='small'
        renderInput={(params) => <TextField   
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
            }
          }}
        size="small"
        variant="outlined"
        required
        {...params} />
        }
        />
        </Grid>
  
        <Grid item xs={12} md={6}>
        <Typography color='#46344E' fontWeight={600}>If yes, Mention the Wikipedia Link</Typography>
              <CustomTextField                                            
      value={wikipediaLink}
      onChange={setWikipediaLink}
      validationRegex={``}
      required={false}
      errorMessage={"Forbidden character: %<>$'\""}
      />
        </Grid>
  
  
        <Grid item xs={12} md={6} display='flex' flexDirection='column' gap={1}>
        <Typography color='#46344E' fontWeight={600}>Have you received any Awards/Media Coverage/Recognitions?</Typography>
        <Typography variant='caption'>Mention the list Awards/Media Coverage/Recognitions & Share the Link. If NO, Kindly Mention as Not Applicable</Typography>
        <CustomTextField                                            
      value={awardsAndCoverage}
      onChange={setAwardsAndCoverage}
      validationRegex={/^[A-Za-z0-9\s\-.,#&'"/]+$/}
      required={true}
      errorMessage={"Forbidden character: %<>$'\""}
      />
        </Grid>

        <Grid item xs={12}>
       <Typography color='#46344E' fontWeight={600}>Synopsis of your movie in short words (not more than 500 words) *</Typography>
       <CustomTextField                                            
      value={synopsis}
      onChange={setSynopsis}
      multiline={true}
      rows={8}
      validationRegex={/^[A-Za-z0-9\s\-.,#&'"/]+$/}
      errorMessage={"Forbidden character: %<>$'\""}
      required={true}
      />
       </Grid>

        <Grid item xs={12} display='flex' flexDirection='column' gap={1}>
        <Typography color='#46344E' fontWeight={600}>Your Movie Cast Details</Typography>
        <Typography variant='caption'>Hero b. Heroine c. Special Apperance d. Supporting Actor e. Supporting Actress f. Other Casts ( if any ) *</Typography>
        <CustomTextField                                            
      value={castDetails}
      onChange={setCastDetails}
      multiline={true}
      rows={8}
      validationRegex={/^[A-Za-z0-9\s\-.,#&'"/]+$/}
      errorMessage={"Forbidden character: %<>$'\""}
      required={true}
      />
        </Grid>
  
  
        <Grid item xs={12} display='flex' flexDirection='column' gap={1}>
        <Typography color='#46344E' fontWeight={600}>Your Movie Crew Details? *</Typography>
        <Typography variant='caption'>Director b. DoP c. Music Director d. Editor e. Art Director f. Lyricist g. Choreographer h. Story Writer i. Screenplay Writer j. Dialogue Writer k. Play Back Singers l. Casting Agency m. PRO etc</Typography>
        <CustomTextField                                            
      value={crewDetails}
      onChange={setCrewDetails}
      multiline={true}
      rows={8}
      validationRegex={/^[A-Za-z0-9\s\-.,#&'"/]+$/}
      errorMessage={"Forbidden character: %<>$'\""}
      required={true}
      />
        </Grid>

        <Grid item xs={12}>
        <FormGroup>
        <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleCheckboxChange} />}
        label="I affirm the accuracy of the movie details provided to the best of my knowledge and authorize their listing in the Marketplace for potential trade. Furthermore, I acknowledge and accept both the terms and conditions and the privacy policy."
        />
        {checked && (
        <Box mt={1}>
        <Typography variant="body2" color="textSecondary"  display='flex' flexWrap='wrap' gap={1}>
        By selecting this checkbox, you affirm that you have reviewed and consent to abide by our Terms and Conditions and Privacy Policy
        </Typography>
        </Box>
        )}
        </FormGroup>
        </Grid>

 
           </Grid>  
         </Box>


         </Container>
 
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 ,  p: 3 }}>

          <Button 
            fullWidth
            variant='contained' 
            color='error'
            onClick={()=>navigate(-1)}
            sx={{
            fontWeight:600 }}>
             Cancel
            </Button>

            <Button 
            fullWidth
            variant='contained' 
            type='submit'
           
            sx={{
            fontWeight:600 , 
            color:'#fff',bgcolor:'#46344E'  ,
            ':hover':{color:'#fff',bgcolor:'#46344E'  }
            }}>
                 Upload
            </Button>
          </Box>
          </form>
        </Box>
  )
}

import React from "react";
import logo from "../Assets/logo-removebg-preview-removebg-preview.png";
import {
  Drawer,
  Typography,
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import { Link } from "react-router-dom";
import ResponsiveBd from "./ResponsiveBd";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PublishIcon from "@mui/icons-material/Publish";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AddBoxIcon from "@mui/icons-material/AddBox";
import QuizIcon from "@mui/icons-material/Quiz";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";

function SideBar({ setMobileOpen, mobileOpen }) {
  const responsive = ResponsiveBd();
  const drawerWidth = 280;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box sx={{ height: "100%" }}>
      {/* Logo */}
      <Box sx={{ px: 2 }}>
        <Box sx={{ textAlign: "center" }}>
          <img
            className="dashlogo"
            src={logo}
            alt="logo"
            style={{
              objectFit: "contain",
              height: "130px",
              width: "100%",
              verticalAlign: "top",
            }}
            loading="lazy"
          />
        </Box>
      </Box>

      <Link to="account" style={{ textDecoration: "none" }}>
        <ListItem
          button
          onClick={handleDrawerToggle}
          sx={{ textAlign: "left", borderRadius: 2, py: 2, color: "#46344E" }}
        >
          <ListItemIcon sx={{ px: 2, color: "#ea3237" }}>
            <AddBoxIcon sx={{ color: "#ea3237" }} />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: 600, fontSize: "1.1rem" }}>
              Create Account
            </Typography>
          </ListItemText>
        </ListItem>
      </Link>

      <Link to="uploads" style={{ textDecoration: "none" }}>
        <ListItem
          button
          onClick={handleDrawerToggle}
          sx={{ textAlign: "left", borderRadius: 2, py: 2, color: "#46344E" }}
        >
          <ListItemIcon sx={{ px: 2, color: "#ea3237" }}>
            <CloudUploadIcon sx={{ color: "#ea3237" }} />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: 600, fontSize: "1.1rem" }}>
              Uploads
            </Typography>
          </ListItemText>
        </ListItem>
      </Link>

      <Link to="listing" style={{ textDecoration: "none" }}>
        <ListItem
          button
          onClick={handleDrawerToggle}
          sx={{ textAlign: "left", borderRadius: 2, py: 2, color: "#46344E" }}
        >
          <ListItemIcon sx={{ px: 2, color: "#ea3237" }}>
            <PublishIcon sx={{ color: "#ea3237" }} />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: 600, fontSize: "1.1rem" }}>
              List Movies
            </Typography>
          </ListItemText>
        </ListItem>
      </Link>

      <Link to="marketplace" style={{ textDecoration: "none" }}>
        <ListItem
          button
          onClick={handleDrawerToggle}
          sx={{ textAlign: "left", borderRadius: 2, py: 2, color: "#46344E" }}
        >
          <ListItemIcon sx={{ px: 2, color: "#ea3237" }}>
            <StorefrontIcon sx={{ color: "#ea3237" }} />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: 600, fontSize: "1.1rem" }}>
              MarketPlace
            </Typography>
          </ListItemText>
        </ListItem>
      </Link>

      <Link to="transaction" style={{ textDecoration: "none" }}>
        <ListItem
          button
          onClick={handleDrawerToggle}
          sx={{ textAlign: "left", borderRadius: 2, py: 2, color: "#46344E" }}
        >
          <ListItemIcon sx={{ px: 2, color: "#ea3237" }}>
            <ReceiptIcon sx={{ color: "#ea3237" }} />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: 600, fontSize: "1.1rem" }}>
              Transaction
            </Typography>
          </ListItemText>
        </ListItem>
      </Link>

      <Link to="enquiry" style={{ textDecoration: "none" }}>
        <ListItem
          button
          onClick={handleDrawerToggle}
          sx={{ textAlign: "left", borderRadius: 2, py: 2, color: "#46344E" }}
        >
          <ListItemIcon sx={{ px: 2, color: "#ea3237" }}>
            <QuizIcon sx={{ color: "#ea3237" }} />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: 600, fontSize: "1.1rem" }}>
              Enquiry Data
            </Typography>
          </ListItemText>
        </ListItem>
      </Link>

      <Link to="cms" style={{ textDecoration: "none" }}>
        <ListItem
          button
          onClick={handleDrawerToggle}
          sx={{ textAlign: "left", borderRadius: 2, py: 2, color: "#46344E" }}
        >
          <ListItemIcon sx={{ px: 2, color: "#ea3237" }}>
            <ContentPasteSearchIcon sx={{ color: "#ea3237" }} />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: 600, fontSize: "1.1rem" }}>
              CMS
            </Typography>
          </ListItemText>
        </ListItem>
      </Link>
    </Box>
  );

  return (
    <Box>
      <Drawer
        open={mobileOpen}
        variant={responsive ? "temporary" : "permanent"}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: "block",
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            bgcolor: "#FFC61A",
            color: "#46443E",
          },
        }}
        anchor="left"
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

export default SideBar;

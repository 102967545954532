import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box,
  Container,
  Stack,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import SnackBar from "../../components/SnackBar";
import Heading from "../../components/Heading/Heading";
import {
  add_faq,
  add_video_link,
  delete_faq,
  delete_video_link,
  get_faq,
  get_video_link,
  update_faq,
  update_video_link,
} from "../../Api/Api";

export default function VideoBox() {
  const [editMode, setEditMode] = useState(false);
  const [Linkid, setLinkid] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data, setData] = useState([]);

  const [editedData, setEditedData] = useState({
    Link: "",
    Linkid: "",
  });

  const [newData, setNewData] = useState({
    Link: "",
    Linkid: "",
  });

  const handleEditClick = (id) => {
    setLinkid(id);
    setEditMode(true);
    getParticularData(id);
  };

  const getAllData = () => {
    const sendData = new FormData();
    sendData.append("id", "");
    sendData.append("fusionUserId", 1);
    axios({
      method: "POST",
      url: get_video_link,
      data: sendData,
      headers: {
        "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
      },
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setData(res.data.data);
          setNewData({
            Linkid: "",
            Link: "",
          });
        } else {
          setOpen(true);
          setStatus(false);
          setColor(false);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  const getParticularData = (id) => {
    if (id !== "" && id !== undefined) {
      const sendData = new FormData();
      sendData.append("id", id);
      sendData.append("fusionUserId", 1);
      axios({
        method: "POST",
        url: get_video_link,
        data: sendData,
        headers: {
          "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
        },
      })
        .then((res) => {
          if (!res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            setEditedData({
              Linkid: res.data.data.id,
              Link: res.data.data.videoLink,
            });
          } else {
            setOpen(true);
            setStatus(false);
            setColor(false);
            setMessage(res.data.message);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
  };

  useEffect(() => {
    getParticularData();
  }, [Linkid]);

  const handleClick = () => {
    const serverData = new FormData();
    serverData.append("videoLink", editedData.Link);
    serverData.append("fusionUserId", 1);
    serverData.append("id", Linkid);

    axios({
      method: "POST",
      url: update_video_link,
      data: serverData,
      headers: {
        "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
      },
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setLinkid("");
          getAllData();

          setEditMode(false);
        } else {
          setOpen(true);
          setStatus(false);
          setColor(false);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Cancel = () => {
    setEditMode(false);
  };

  const DeleteData = (id) => {
    if (id !== "") {
      const sendData = new FormData();
      sendData.append("id", id);
      sendData.append("fusionUserId", 1);
      axios({
        method: "POST",
        url: delete_video_link,
        data: sendData,
        headers: {
          "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
        },
      })
        .then((res) => {
          if (!res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            getAllData();
          } else {
            setOpen(true);
            setStatus(false);
            setColor(false);
            setMessage(res.data.message);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
  };

  const AddNew = () => {
    if (newData.Link !== "" && newData.Link !== "") {
      const serverData = new FormData();
      serverData.append("videoLink", newData.Link);
      serverData.append("fusionUserId", 1);
      axios({
        method: "POST",
        url: add_video_link,
        data: serverData,
        headers: {
          "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
        },
      })
        .then((res) => {
          if (!res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            getAllData();
          } else {
            setOpen(true);
            setStatus(false);
            setColor(false);
            setMessage(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setOpen(true);
      setStatus(false);
      setColor(false);
      setMessage("Fill all the Fields");
    }
  };

  return (
    <Box>
      <SnackBar
        open={open}
        setOpen={setOpen}
        status={status}
        color={color}
        message={message}
      />

      <Container>
        <Grid container spacing={4} mb={2}>
          {data &&
            data.map((item) => (
              <Grid item xs={12} sm={12} md={6} lg={6} key={item.id}>
                {editMode && Linkid === item.id ? (
                  <Stack spacing={2}>
                    <TextField
                      label="Question"
                      fullWidth
                      variant="outlined"
                      value={editedData.Link}
                      onChange={(e) =>
                        setEditedData({
                          ...editedData,
                          Link: e.target.value,
                        })
                      }
                    />
                    <Box display="flex" flexDirection="row">
                      <Button onClick={handleClick}>Update</Button>
                      <Button onClick={Cancel} color="error">
                        Cancel
                      </Button>
                    </Box>
                  </Stack>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    pb={1}
                    justifyContent="start"
                  >
                    <Box textAlign="left">
                      <Typography variant="subtitle2">
                        <strong> Video Link: </strong>
                        {item.videoLink}
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="row">
                      <IconButton
                        onClick={() => handleEditClick(item.id)}
                        color="primary"
                      >
                        <EditIcon sx={{ verticalAlign: "top" }} />
                      </IconButton>

                      <IconButton
                        onClick={() => DeleteData(item.id)}
                        color="error"
                      >
                        <DeleteIcon sx={{ verticalAlign: "top" }} />
                      </IconButton>
                    </Box>
                  </Box>
                )}
              </Grid>
            ))}
        </Grid>

        {/* NEW Step */}

        <Divider />

        <Box mt={5} mb={2}>
          <Heading title={"Add New Link"} />
        </Box>

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7}
            display="flex"
            justifyContent="start"
          >
            <TextField
              label="Link"
              fullWidth
              variant="outlined"
              value={newData.Link}
              onChange={(e) => setNewData({ ...newData, Link: e.target.value })}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            display="flex"
            justifyContent="start"
          >
            <Box display="flex" flexDirection="row">
              <Button variant="contained" onClick={AddNew}>
                Generate
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

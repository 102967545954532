import React from "react";
import Box from "@mui/material/Box";
import { Container, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Carousel from "./Carousel";
import Deals from "./Deals";
import StepByStep from "./StepByStep";
// import TopCollect from "./TopCollect";
import Alliance from "./Alliance";
import ContentBuyer from "./ContentBuyer";
import Endorse from "./Endorse";
import Mentions from "./Mentions";
import VideoBox from "./VideoBox";
import Blogs from "./Blogs";
import FAQ from "./FAQ";

const CMSScreens = [
  {
    title: "Carousel",
    component: Carousel,
  },
  {
    title: "Deals Done",
    component: Deals,
  },
  {
    title: "Step By Step",
    component: StepByStep,
  },
  // {
  //   title: "Top Collection",
  //   component: TopCollect,
  // },
  {
    title: "Allaince Creds",
    component: Alliance,
  },
  {
    title: "Content Buyer",
    component: ContentBuyer,
  },
  {
    title: "Endorsements",
    component: Endorse,
  },
  {
    title: "Media Mention",
    component: Mentions,
  },
  {
    title: "Video Box",
    component: VideoBox,
  },
  {
    title: "Blogs",
    component: Blogs,
  },
  {
    title: "FAQ",
    component: FAQ,
  },
];

export default function CMS() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} mt={4} mb={1}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="success"
          textColor="inherit"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="full width tabs example"
        >
          {CMSScreens.map((screen, index) => (
            <Tab
              key={index}
              label={screen.title}
              sx={{
                bgcolor: value === index && "#FFC61A",
                color: value === index ? "#fff" : "#000",
                border: value === index && "1px solid silver",
                borderRadius: value === index && "4px",
                textTransform: "none",
              }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>

      {CMSScreens.map((screen, index) => (
        <TabPanel key={index} value={value} index={index}>
          <screen.component setValue={setValue} />
        </TabPanel>
      ))}
    </Container>
  );
}

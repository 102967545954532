import React, {useState, Use, useEffect} from 'react'
import { Avatar, Box, Button, Card, Container, Grid, Paper, Stack, Typography, TextField, Autocomplete, Checkbox } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import SnackBar from '../../components/SnackBar';
import ListAssetDialog from './ListDialog';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function ListMovieDetails() {

        const [value, setValue] = useState(0);
        const navigate = useNavigate();
        const [activeStep, setActiveStep] = useState(0);
        const [skipped, setSkipped] = useState(new Set());
        const [open, setOpen] = useState(false);
        const [status, setStatus] = useState(false);
        const [color, setColor] = useState(false);
        const [message, setMessage] = useState("");
        const location = useLocation();
        const {data} = location.state;
        const [imageFile, setImageFile] = useState(null);
        const [OpenDialog, setOpenDialog] = useState(false)
        const [openBackdrop, setOpenBackdrop] = useState(false);
        const [BackDropText, setBackDropText] = useState('');

       

       const handleDialog = () =>{
         setOpenDialog(true)
       }


        
  return (
    <Box p={3}>


      <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={openBackdrop}
      >
      <Box display='flex' flexDirection='row' gap={2}>
      <Box py={1}>
      <Typography>{BackDropText}</Typography>
      </Box>
      <CircularProgress color="secondary" />
      </Box>
      </Backdrop>

      <ListAssetDialog
      setOpenDialog={setOpenDialog} 
      OpenDialog={OpenDialog} 
      dialogData={data} 
      setMessage={setMessage} 
      setColor={setColor} 
      setOpen={setOpen} 
      setStatus={setStatus}
      rightsId={data?.rightsId}
      />

   <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />

    <Box p={1}>

          <Container>

      <Grid container spacing={3} rowSpacing={5} >

      <Grid item xs={12} mb={2}>
         <img src={data?.moviePoster} alt="poster" style={{objectFit:'scale-down', width:'100%', height:550}} />
      </Grid>


         {/* <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>First Name</Typography>
       <Typography>{data?.producerFirstName}</Typography>
      </Grid>

         <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
      <Typography color='#46344E' fontWeight={600}>Last Name</Typography>
      <Typography>{data?.producerLastName}</Typography>
      </Grid>

        <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
      <Typography color='#46344E' fontWeight={600}>Production House</Typography>
      <Typography>{data?.productionName}</Typography>
      </Grid> */}

           <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
        <Typography color='#46344E' fontWeight={600}>Movie Title</Typography>
        <Typography>{data?.movieTitle}</Typography>
       </Grid>
 
 
          <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>Available Languages</Typography>
       <Typography>{data?.languages}</Typography>
       </Grid>
 
          <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>Genre</Typography>
       <Typography>{data?.movieGenre}</Typography>
       </Grid>
 
 
       <Grid item xs={12}>
       <Typography color='#46344E' fontWeight={600}>Synopsis</Typography>
       <Typography>{data?.movieSynopsis}</Typography>
       </Grid>
 

            <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
         <Typography color='#46344E' fontWeight={600}>Rights</Typography>
         <Typography>{data?.movieRights}</Typography>
        </Grid>

        <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
        <Typography color='#46344E' fontWeight={600}>Movie Cast Details</Typography>
        <Typography>{data?.movieCast}</Typography>
        </Grid>
  
  
        <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
        <Typography color='#46344E' fontWeight={600}>Movie Crew Details</Typography>
        <Typography>{data?.movieCrew}</Typography>
        </Grid>
    </Grid>
    <Grid container spacing={3}>
    <Grid item xs={6}>
          <Box sx={{ pt: 2 }} onClick={()=>navigate(-1)}>
            <Button fullWidth variant='contained' color='error' sx={{fontWeight:600}}>
             Go Back
            </Button>
          </Box>
          </Grid>

        <Grid item xs={6}>
          <Box sx={{ pt: 2 }}>
            <Button onClick={handleDialog} fullWidth variant='contained' sx={{bgcolor:'#FFC61A', fontWeight:600 , color:'#46344E', ':hover':{color:'#46344E',bgcolor:'#FFC61A'  }}}>
             List For Sale
            </Button>
          </Box>
          </Grid>
    </Grid>
    </Container>
    </Box>
  

  </Box>
  )
}

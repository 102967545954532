import React, { useEffect, useState } from 'react'
import { Button, DialogContentText,Dialog, DialogActions, DialogContent, DialogTitle, } from '@mui/material';
import axios from 'axios';
import { add_nft_created_movies,} from '../../Api/Api';
import { useWallet } from '@solana/wallet-adapter-react';
import {
    clusterApiUrl,
    PublicKey,
  Transaction,
    Connection
} from '@solana/web3.js';
import { useNavigate } from 'react-router-dom';
import { inAppWalletAdapter, useConnection } from '../../Solana.utils';
import Slide from '@mui/material/Slide';
import { LoadingButton } from '@mui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function CreateAssetDialog({ 
     setMessage, 
     setOpen, 
     setStatus, 
     setColor, 
     setOpenDialog,
     OpenDialog,
     dialogData, 
     rightsId,
     rightName
 }) {


 const navigate = useNavigate();
 const movieRightsArray =  dialogData?.movieRights?.replace(/"/g, '').split(', ');
 const [rightsCategory, setRightsCategory] = useState(null);
 const [rightsSubCategoryList, setRightsSubCategoryList] = useState([]);
 const [rightsSubCategory, setRightsSubCategory] = useState(null);

 const [loading, setLoading] = useState(false);
 const [publicKey, setPublicKey] = useState(null);
 const [WalletConnected , setWalletConnected] = useState(true);
 const { connect, disconnect, connected } = useWallet();
 const connection = useConnection();
 const [keypair, setKeypair] = useState(null);


  const handleApiKey = (apiKeyString) => {
  const publicKey = new PublicKey(apiKeyString);
  setPublicKey(publicKey);
  };

  useEffect(() => {
    const apiPublicKey = process.env.REACT_APP_PUBLICKEY;
  handleApiKey(apiPublicKey);
  }, []);


    const handleClose = () => {
        setOpenDialog(false);
        setRightsCategory(null);
        setRightsSubCategory(null);
    }



  function base64ToFile(base64String, fileName) {
    const chunks = base64String.match(/.{1,1024}/g);
      
    let decodedString = '';
    for (let i = 0; i < chunks.length; i++) {
        decodedString += atob(chunks[i]);
    }
  
    const byteCharacters = decodedString;
  
    const byteArrays = [];
    for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
    }
  
    const byteArray = new Uint8Array(byteArrays);
  
    const blob = new Blob([byteArray]);

    const file = new File([blob], fileName);
  
      return new File([blob], fileName);
      }

    const base64String = dialogData?.moviePosterBase64;
     const fileName = `${dialogData?.movieTitle}.jpg`; 
  
      const convertedFile = base64ToFile(base64String, fileName);


    const createNFT = async () => {
      try {
        if (publicKey) {
          setLoading(true)
          // const publicKeyString = wallet.publicKey.toBase58();
          const formData = new FormData();
          formData.append("network", "devnet");
          formData.append("creator_wallet", publicKey);
          formData.append("name", dialogData?.movieTitle);
          formData.append("symbol", "PB");
          formData.append("description",rightName);
          formData.append("image", convertedFile , fileName);
          formData.append("royalty", 5);
          formData.append('service_charge', JSON.stringify({
            "receiver":process.env.REACT_APP_RECEIVER,
            "amount": 0.01
          }));
    
          const axiosConfig = {
            headers: {
              "x-api-key": process.env.REACT_APP_APIKEY,
            },
          };
    
          axios.post("https://api.shyft.to/sol/v2/nft/create", formData, axiosConfig)
            .then(async response => {
              const encodedTransaction = response.data.result.encoded_transaction;
              const connection = new Connection(clusterApiUrl("devnet"), 'confirmed');
              const recoveredTransaction = Transaction.from(
                Uint8Array.from(atob(encodedTransaction), c => c.charCodeAt(0))
              );
              const signedTx = await inAppWalletAdapter.signTransaction(recoveredTransaction);
              const confirmTransaction = await connection.sendRawTransaction(signedTx.serialize());
              return { mint: response.data.result.mint, confirmTransaction };
            })
            .then(({ mint }) => {
              CreateDigital(mint);
            })
            .catch((error) => {
              setMessage('Something Went Wrong');
              setOpen(true);
              setStatus(false);
              setColor(false);
              setLoading(false);
              console.error('Error creating NFT:', error);
            });
        }
      } catch (error) {
        setMessage('Something Went Wrong');
        setOpen(true);
        setStatus(false);
        setColor(false);
        setLoading(false);
        console.error('Error in createNFT:', error);
      }
    };
    


      const CreateDigital = (mintAddress) => {
        // const publicKeyString = wallet.publicKey.toBase58();
        const sendData = new FormData()
        sendData.append('rightsId',  rightsId);
        sendData.append('movieId', dialogData?.movieId);
        sendData.append('creatorAddress', publicKey );
        sendData.append('mint', mintAddress);
        sendData.append('sellingRights', rightName);
        sendData.append('sellingParticularRights', '');
        sendData.append('fusionUserId', 1);
        axios({
          method: 'POST',
          url: add_nft_created_movies,
          data:sendData,
          headers: {
            'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
        }
        }).then(res => {
          if (!res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(true)
            setColor(true)
            setLoading(false);
            navigate('/app/listing');
            handleClose();
          } else {
            setOpen(true)
            setStatus(false)
            setColor(false)
            setMessage(res.data.message)
            setLoading(false);
          }
        }).catch(err => {
          alert('Oops something went wrong ' + err)
          setLoading(false);
        });
      }


    return (
<Dialog
onClose={handleClose} 
open={OpenDialog} 
fullWidth
TransitionComponent={Transition}
keepMounted
aria-describedby="alert-dialog-slide-description"
>
<DialogTitle>{"Create Digital Assets For this Movie Right"}</DialogTitle>
<DialogContent>
  <DialogContentText id="alert-dialog-slide-description">
  You are about to create digital assets for the selected movie right. This process involves generating digital content to be used for distribution or marketing purposes.
  </DialogContentText>
</DialogContent>
<DialogActions>
<Button size='small'   sx={{width:'20ch'}} onClick={handleClose} variant='outlined' color='error'>CLOSE</Button>

  <LoadingButton
  size="small"
  color='success'
  onClick={createNFT}
  loading={loading}
  sx={{width:'20ch'}}
  loadingPosition="end"
  variant="outlined"
  >
  <span>
  CREATE
  </span>
  </LoadingButton>
</DialogActions>
</Dialog>
    )
}

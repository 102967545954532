import {
  Container,
  Box,
  Grid,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import SnackBar from "../../components/SnackBar";
import Heading from "../../components/Heading/Heading";

import {
  add_stack_cards,
  delete_stack_cards,
  get_stack_cards,
  update_stack_cards,
} from "../../Api/Api";

export default function Carousel() {
  const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [imageId, setImageId] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data, setData] = useState([]);

  const getAllData = () => {
    const sendData = new FormData();
    sendData.append("id", "");
    sendData.append("fusionUserId", 1);
    axios({
      method: "POST",
      url: get_stack_cards,
      data: sendData,
      headers: {
        "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
      },
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setData(res.data.data);
        } else {
          setOpen(true);
          setStatus(false);
          setColor(false);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  const handleEditClick = (id) => {
    setImageId(id);
    setEditMode(true);
  };

  const handleImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedImage(newImage);
  };

  const handleClick = () => {
    const sendData = new FormData();
    sendData.append("fusionUserId", 1);
    sendData.append("id", imageId);
    sendData.append("images", selectedImage);
    axios({
      method: "POST",
      url: update_stack_cards,
      data: sendData,
      headers: {
        "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
      },
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setSelectedImage("");
          setImageId("");
          getAllData();
        } else {
          setOpen(true);
          setStatus(false);
          setColor(false);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Cancel = () => {
    setSelectedImage(null);
    setEditMode(false);
  };

  const DeleteData = (Imageid) => {
    if (Imageid !== "") {
      const sendData = new FormData();
      sendData.append("id", Imageid);
      sendData.append("fusionUserId", 1);
      axios({
        method: "POST",
        url: delete_stack_cards,
        data: sendData,
        headers: {
          "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
        },
      })
        .then((res) => {
          if (!res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            getAllData();
          } else {
            setOpen(true);
            setStatus(false);
            setColor(false);
            setMessage(res.data.message);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
  };

  const AddNew = () => {
    const serverData = new FormData();
    serverData.append("fusionUserId", 1);
    serverData.append("images", newImage);
    axios({
      method: "POST",
      url: add_stack_cards,
      data: serverData,
      headers: {
        "x-api-key": process.env.REACT_APP_SUBSCRIPTION_KEY,
      },
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setNewImage(null);
          document.getElementById("newsliderImage").value = "";
          getAllData();
        } else {
          setOpen(true);
          setStatus(false);
          setColor(false);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box>
      <SnackBar
        open={open}
        setOpen={setOpen}
        status={status}
        color={color}
        message={message}
      />

      <Container>
        <Grid container spacing={2}>
          {data &&
            data?.map((image) => (
              <Grid item xs={12} sm={6} md={6} lg={4} key={image.id}>
                {editMode && imageId === image.id ? (
                  <>
                    <TextField
                      type="file"
                      onChange={handleImageChange}
                      inputProps={{ accept: "image/*" }}
                      sx={{ marginTop: 10 }}
                    />
                    <Button onClick={handleClick}>Update</Button>
                    <Button onClick={Cancel} color="error">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Box display="flex" flexDirection="row" alignContent="center">
                    <img
                      src={image.images}
                      style={{ width: 200, height: 200, objectFit: "contain" }}
                      alt=""
                    />
                    <Box display="flex" flexDirection="column" mt={5}>
                      <IconButton
                        onClick={() => handleEditClick(image.id)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => DeleteData(image.id)}
                        color="error"
                        sx={{ marginTop: 1 }}
                      >
                        <DeleteIcon sx={{ verticalAlign: "middle" }} />
                      </IconButton>
                    </Box>
                  </Box>
                )}
              </Grid>
            ))}
        </Grid>

        <Box mt={2}>
          <Heading title={"Add New Image"} />
        </Box>

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            display="flex"
            justifyContent="start"
          >
            <TextField
              type="file"
              size="medium"
              id="newsliderImage"
              onChange={(e) => setNewImage(e.target.files[0])}
              inputProps={{ accept: "image/*" }}
              sx={{ marginTop: 2 }}
            />
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Button
                variant="contained"
                color="warning"
                sx={{ marginTop: 4 }}
                onClick={AddNew}
              >
                Generate
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

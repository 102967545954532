import React, {useState, Use, useEffect} from 'react'
import { Avatar, Box, Button, Card, Container, Grid, Paper, Stack, Typography, TextField, Autocomplete, Checkbox } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import CreateAssetDialog from './CreateAssetDialog';
import SnackBar from '../../components/SnackBar';
import axios from 'axios';
import { get_movie_details } from '../../Api/Api';

export default function UploadedDetails() {

        const [value, setValue] = useState(0);
        const navigate = useNavigate();
        const [activeStep, setActiveStep] = useState(0);
        const [skipped, setSkipped] = useState(new Set());
        const [open, setOpen] = useState(false);
        const [status, setStatus] = useState(false);
        const [color, setColor] = useState(false);
        const [message, setMessage] = useState("");
        const location = useLocation();
        const {movieId , rightsId} = location.state;
        const [imageFile, setImageFile] = useState(null);
        const [data , setData]= useState([]);
        const [OpenDialog, setOpenDialog] = useState(false)



        const getAllData = () => {
         const sendData = new FormData()
         sendData.append('movieId', movieId);
         sendData.append('fusionUserId', 1);
         sendData.append('rightsId', rightsId);
         axios({
           method: 'POST',
           url: get_movie_details,
           data:sendData,
           headers: {
             'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
         }
         }).then(res => {
           if (!res.data.error) {
             setMessage(res.data.message)
             setOpen(true)
             setStatus(true)
             setColor(true)
             setData(res.data.data)
           } else {
             setOpen(true)
             setStatus(false)
             setColor(false)
             setMessage(res.data.message)
           }
         }).catch(err => {
           alert('Oops something went wrong ' + err)
         });
       }
     
       useEffect(() => {
         getAllData();
       }, [movieId, rightsId]);
       

       const handleDialog = () =>{
         setOpenDialog(true)
       }

       console.log(data)
        
  return (
    <Box p={3}>
       {
         data.length !== 0 &&
      <CreateAssetDialog 
      setOpenDialog={setOpenDialog} 
      OpenDialog={OpenDialog} 
      dialogData={data} 
      setMessage={setMessage} 
      setColor={setColor} 
      setOpen={setOpen} 
      setStatus={setStatus}
      rightsId={rightsId}
      rightName={data?.movieRights}
      />

   }

   <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />

    <Box p={1}>

          <Container>

      <Grid container spacing={3} rowSpacing={5} >

      <Grid item xs={12} mb={2}>
         <img src={data?.moviePoster} alt="poster" style={{objectFit:'scale-down', width:'100%', height:550}} />
      </Grid>

         {/* <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>First Name</Typography>
       <Typography>{data?.producerFirstName}</Typography>
      </Grid> */}

         {/* <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
      <Typography color='#46344E' fontWeight={600}>Last Name</Typography>
      <Typography>{data?.producerLastName}</Typography>
      </Grid> */}

        {/* <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
      <Typography color='#46344E' fontWeight={600}>Production House</Typography>
      <Typography>{data?.productionName}</Typography>
      </Grid>
 */}

         {/* <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
      <Typography color='#46344E' fontWeight={600}>Street 1</Typography>
      <Typography>{data?.contactAddress1}</Typography>
      </Grid> */}


         {/* <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
      <Typography color='#46344E' fontWeight={600}>Street 2</Typography>
      <Typography>{data?.contactAddress2}</Typography>
      </Grid> */}


         {/* <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
      <Typography color='#46344E' fontWeight={600}>City</Typography>
      <Typography>{data?.cityName}</Typography>
      </Grid> */}

         {/* <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
      <Typography color='#46344E' fontWeight={600}>State</Typography>
      <Typography>{data?.stateName}</Typography>
      </Grid> */}

         {/* <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
      <Typography color='#46344E' fontWeight={600}>Postal Code</Typography>
      <Typography>{data?.postalCode}</Typography>
      </Grid> */}

         {/* <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
      <Typography color='#46344E' fontWeight={600}>Country</Typography>
      <Typography>{data?.countryName}</Typography>
      </Grid> */}

         {/* <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
      <Typography color='#46344E' fontWeight={600}>Email ID</Typography>
      <Typography>{data?.emailId}</Typography>
      </Grid> */}


         {/* <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
      <Typography color='#46344E' fontWeight={600}>Contact Number</Typography>
      <Typography>{data?.mobile}</Typography>
      </Grid> */}



           <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
        <Typography color='#46344E' fontWeight={600}>Movie title to be Listed?</Typography>
        <Typography>{data?.movieTitle}</Typography>
       </Grid>
 
          <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>Have you Applied for Censor?</Typography>
       <Typography>{data?.isCensorApplied}</Typography>
       </Grid>
 
          <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>If Yes, Upload your Censor Certificate</Typography>
       <Typography>{data?.censorCertificate}</Typography>
       </Grid>
 
 
          <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>Your movie Duration? (mention in minutes - number)</Typography>
       <Typography>{data?.movieDuration}</Typography>
       </Grid>
 
 
          <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>Available Languages</Typography>
       <Typography>{data?.languages}</Typography>
       </Grid>
 
          <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>Genre</Typography>
       <Typography>{data?.movieGenre}</Typography>
       </Grid>
 
          <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>Movie Release Status</Typography>
       <Typography>{data?.movieReleaseStatus}</Typography>
       </Grid>


       <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>Does your movie registered under IPR Act?</Typography>
       <Typography>{data?.isIPRRegistered}</Typography>
       </Grid>

       <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>Agency/Distributor/ Aggregator Name</Typography>
       <Typography>{data?.agencyName}</Typography>
       </Grid>
 
          <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>Agency/Distributor/Aggregator Contact Number?</Typography>
       <Typography>{data?.agencyMobile}</Typography>
       </Grid>
 
 
       <Grid item xs={12}>
       <Typography color='#46344E' fontWeight={600}>Synopsis of your movie in short words (not more than 500 words)</Typography>
       <Typography>{data?.movieSynopsis}</Typography>
       </Grid>
 

            <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
         <Typography color='#46344E' fontWeight={600}>Choose the rights among the list which you wish to trade</Typography>
         <Typography>{data?.movieRights}</Typography>
        </Grid>

           {/* <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
         <Typography color='#46344E' fontWeight={600}>Choose the rights from the Selected Category</Typography>
         <Typography>{data?.particularRights}</Typography>
        </Grid> */}
  
           <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
        <Typography color='#46344E' fontWeight={600}>Share the movie Trailer / Promo Link</Typography>
        <Typography>{data?.movieTrailerLink}</Typography>
        </Grid>
  
           <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
        <Typography color='#46344E' fontWeight={600}>Is your Movie listed in IMDB?</Typography>
        <Typography>{data?.isIMBDListedMovie}</Typography>
        </Grid>
  
           <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
        <Typography color='#46344E' fontWeight={600}>If yes, Mention the IMDB Link</Typography>
        <Typography>{data?.IMDBLink}</Typography>
        </Grid>
  
           <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
        <Typography color='#46344E' fontWeight={600}>Is your Movie listed in Wikipedia?</Typography>
        <Typography>{data?.isWikipediaListedMovie}</Typography>
        </Grid>
  
           <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
        <Typography color='#46344E' fontWeight={600}>If yes, Mention the Wikipedia Link</Typography>
        <Typography>{data?.wikipediaLink}</Typography>
        </Grid>
  
  
        <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
        <Typography color='#46344E' fontWeight={600}>Have you received any Awards/Media Coverage/Recognitions?</Typography>
        <Typography>{data?.receivedAwards}</Typography>
        </Grid>

        <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
        <Typography color='#46344E' fontWeight={600}>Your Movie Cast Details</Typography>
        <Typography>{data?.movieCast}</Typography>
        </Grid>
  
  
        <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
        <Typography color='#46344E' fontWeight={600}>Your Movie Crew Details?</Typography>
        <Typography>{data?.movieCrew}</Typography>
        </Grid>
    </Grid>
    <Grid container spacing={3}>
    <Grid item xs={6}>
          <Box sx={{ pt: 2 }} onClick={()=>navigate(-1)}>
            <Button fullWidth variant='contained' color='error' sx={{fontWeight:600}}>
             Go Back
            </Button>
          </Box>
          </Grid>

        <Grid item xs={6}>
          <Box sx={{ pt: 2 }}>
            <Button onClick={handleDialog} fullWidth variant='contained' sx={{bgcolor:'#FFC61A', fontWeight:600 , color:'#46344E', ':hover':{color:'#46344E',bgcolor:'#FFC61A'  }}}>
             Create
            </Button>
          </Box>
          </Grid>
    </Grid>
    </Container>
    </Box>
  

  </Box>
  )
}

import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Box , Container , Stack, Typography , Button, Autocomplete, Pagination, Card,
  TablePagination
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { get_movie_details } from "../../Api/Api";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useLocation, useNavigate } from 'react-router-dom';
import Heading from "../../components/Heading/Heading";
import Nodata from "../../components/Nodata";
import Spinner from "../../components/Spinner/Spinner";
import DeleteDialog from "./DeleteDialog";
import SnackBar from "../../components/SnackBar";

export default function UploadedMovies() {


  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);
  const [loading , setLoading] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [totalPages, setTotalPages] = useState(0); 
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedMovie , setSelectedMovie] = useState('');
  const [selectedMovieId , setSelectedMovieId] = useState('');
  const [selectedrightsId, setSelectedrightsId] = useState(''); 

  const getAllData = () => {
    setLoading(true);
    const sendData = new FormData()
    sendData.append('movieId', '');
    sendData.append('fusionUserId', 1);
    sendData.append('rightsId', '');
    sendData.append('noOfData', rowsPerPage);
    sendData.append('pageNo', page + 1);
    axios({
      method: 'POST',
      url: get_movie_details,
      data:sendData,
      headers: {
        'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
    }
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
        setTotalPages(res.data.totalCount)
        setLoading(false);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
        setLoading(false)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
      setLoading(false)
    });
  }

  useEffect(() => {
    getAllData();
  }, [page , rowsPerPage]);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialog = (id ,right ,  movie) =>{
     setSelectedMovie(movie)
     setOpenDeleteDialog(true)
     setSelectedMovieId(id);
     setSelectedrightsId(right);
  }

  return (
    <Box p={3}>
      <Box my={1} mb={2}>
        <Heading title={'Uploaded Movies'} />
      </Box>
      <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
      <DeleteDialog getAllData={getAllData} setSelectedrightsId={setSelectedrightsId} selectedrightsId={selectedrightsId} selectedMovieId={selectedMovieId} setSelectedMovieId={setSelectedMovieId} setOpen={setOpen} setColor={setColor} setMessage={setMessage} setStatus={setStatus} movieName={selectedMovie} openDeleteDialog={openDeleteDialog} setOpenDeleteDialog={setOpenDeleteDialog} />
      <>
       {loading ? 
      <div style={{display:'flex', justifyContent:'center', alignContent:'center', alignItems:'center', paddingTop:50}}>
      <Spinner />
      </div>
      :
    <Grid container spacing={2}>
    {
     data?.length !== 0 ? data?.map((i , index)=>{
        return(
          <Grid item xs={12} sm={6} md={3} key={index} >
          <Card sx={{ maxWidth: 350 ,boxShadow:5 , height:'100%' , display:'flex' , flexDirection:'column', justifyContent:'space-between' }}>
          <CardMedia
          component="img"
          alt="green iguana"
          height="200"
          image={i.moviePoster}
        />
        <CardContent>
          <Box display='flex' flexDirection='column' gap={1.5}>
          <Box display='flex' flexDirection='row' gap={1}>
          <Typography variant="subtitle2"><strong>Production House: </strong>{i.productionName}</Typography>
          </Box>
          <Box display='flex' justifyContent='start' textAlign='left' flexDirection='column' gap={1}>
          <Typography variant="subtitle2" color='#101010'>
          <strong>Movie Title: </strong> {i.movieTitle}
          </Typography>
          <Typography variant="subtitle2" color='#101010'><strong>Rights: </strong>{i.movieRights}</Typography>
          </Box>
          </Box>
        </CardContent>
        <Stack p={2} spacing={1}>
        <Box>
         <Button 
         fullWidth 
         variant="contained" 
         onClick={()=>navigate('uploadeddetail' , {state:{movieId: i.movieId , rightsId: i.rightsId}})}
         sx={{
          bgcolor:'#FFC61A', 
          fontWeight:600 , 
          color:'#46344E', 
          ':hover':{color:'#46344E',bgcolor:'#FFC61A'  }}}>
          VERIFY
          </Button>  
        </Box>
        <Box>
         <Button 
         fullWidth 
         variant="contained" 
         color='primary'
         onClick={()=>navigate('updateuploads' , {state:{MovieId: i.movieId}})}
         sx={{fontWeight:600 }}>
          UPDATE
          </Button>  
        </Box>
        <Box>
         <Button 
         fullWidth 
         onClick={()=>handleOpenDialog(i.movieId, i.rightsId, i.movieTitle)}
         variant="contained" 
         color='error'
         sx={{
          fontWeight:600 }}>
          DELETE
          </Button>  
        </Box>
        </Stack>

         </Card>
          </Grid>
        )
      })
      :
      <Grid item xs={12}>
        <Box p={3}>
            <Nodata />
        </Box>
      </Grid>
    }
      <Grid item xs={12}>
        {
        data?.length !== 0  &&
        <Box mt={3} display="flex" justifyContent="end">
      <TablePagination
      component="div"
      count={totalPages}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
        </Box>
        }
      </Grid>

    </Grid>
}
</>

</Box>
  )
}

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import { Container, Grid } from '@mui/material';
import Heading from '../../components/Heading/Heading';
import { add_wallet_details, get_user_payment_details } from '../../Api/Api';
import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { clusterApiUrl  , Keypair , PublicKey } from "@solana/web3.js";
import { decodeBase64, encodeBase64 } from '../../Solana.utils';
import SnackBar from '../../components/SnackBar';
import CryptoJS from 'crypto-js';
import Spinner from '../../components/Spinner/Spinner';

export default function AccountList() {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [data , setData] = useState([]);
  const [loading, setLoading] = React.useState(false);

  const getAllData = () => {
    setLoading(true);
    const sendData = new FormData()
    sendData.append('fusionUserId', 1);
    axios({
      method: 'POST',
      url: get_user_payment_details,
      data:sendData,
      headers: {
        'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
    }
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
        setLoading(false);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
        setData([])
        setLoading(false);
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
      setLoading(false);
    });
  }

  useEffect(() => {
    getAllData();
  }, []);

  const keys = data ? Object.keys(data[0] || {}).filter((key) => key !== "userId") : [];

        const handleChangePage = (event, newPage) => {
        setPage(newPage);
        };
        const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        };


        const encryptData = (data) => {
            const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'secretKey');
            return ciphertext.toString();
          };
        


        const generateNewAccount = async (row , index) => {
            if (loading[index]) return;

            const newKeypair = Keypair.generate();
            setLoading(prevLoading => {
              const newLoading = [...prevLoading];
              newLoading[index] = true;
              return newLoading;
            });;
        
            // Convert keys to base64 strings
            const publicKeyBase64 = newKeypair.publicKey.toBase58();
            const secretKeyBase64 = encodeBase64(newKeypair.secretKey);

            const encryptedsecretKeyBase64 = encryptData(secretKeyBase64);

            console.log(publicKeyBase64);
            console.log(secretKeyBase64);
        
            // Decode the private key before creating a Keypair
            const decodedPrivateKey = decodeBase64(secretKeyBase64);
        
            try {
              const keypair = Keypair.fromSecretKey(decodedPrivateKey);
               await addwallet(encryptedsecretKeyBase64, publicKeyBase64, keypair, row);
            } catch (error) {
              setOpen(true)
              setStatus(false)
              setColor(false)
              setMessage('Error creating Keypair:', error);
              setLoading(prevLoading => {
                const newLoading = [...prevLoading];
                newLoading[index] = false;
                return newLoading;
              });
            }
          };


          const addwallet = async (secretKey , publicKey, keypair , row , index) =>{
            const sendData = new FormData()
            sendData.append('fusionUserId', 1);
            sendData.append('privateKey', secretKey);
            sendData.append('publicKey', publicKey);
            sendData.append('keypair', JSON.stringify(keypair));
            sendData.append('userId', row.userId);
            sendData.append('names', row.userName);
            sendData.append('mobileNo', row.userMobile);
            sendData.append('emailId', row.userMail);
            axios({
              method: 'POST',
              url: add_wallet_details,
              data:sendData,
              headers: {
                'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
            }
            }).then(res => {
              if (!res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(true)
                setColor(true)
                setData(res.data.data)
             
                getAllData();
                setLoading(prevLoading => {
                    const newLoading = [...prevLoading];
                    newLoading[index] = false;
                    return newLoading;
                  });
              } else {
                setOpen(true)
                setStatus(false)
                setColor(false)
                setMessage(res.data.message)
                setLoading(prevLoading => {
                    const newLoading = [...prevLoading];
                    newLoading[index] = false;
                    return newLoading;
                  });
              }
            }).catch(err => {
              alert('Oops something went wrong ' + err)
            });
          }

  return (
    <Box p={2}>

    <Box my={1} mb={2}>
    <Heading title={'New Registrations'} />
    </Box>

    <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />

    <>
      {loading ? 
      <div style={{display:'flex', justifyContent:'center', alignContent:'center', alignItems:'center', paddingTop:50}}>
      <Spinner />
      </div>
      :

    <Grid container spacing={1}>
      <TableContainer sx={{boxShadow:8}}>
         <Table>
         <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#46444E' }}>
          <TableRow>
            {keys.map((key) => (
             <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center'  }} key={key}>
                <Box sx={{ display: 'flex' , justifyContent:'center'}}>
                <Box sx={{ my: 'auto', textTransform: 'uppercase', textAlign: 'center', fontWeight: '600' }}>
                {key.split(/(?=[A-Z])/).join(' ')}
                </Box>
            </Box>
             </TableCell>
            ))}
            <TableCell sx={{color: 'white', fontWeight: 600 , textAlign:'center' , textTransform: 'uppercase',  my: 'auto'}}>Create Wallet</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow key={index}>
                {keys.map((key) => (
                  <TableCell align="center" key={key}>
                    { row[key] ?  row[key] : "-" }
                  </TableCell>
                ))}
                <TableCell>
                <LoadingButton
                        size="small"
                        onClick={()=>generateNewAccount(row, index)}
                        endIcon={<AddCircleIcon />}
                        loading={loading[index]}
                        loadingPosition="end"
                        variant="contained"
                      >
                        <span>Generate</span>
                      </LoadingButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
          </Grid>
}
</>
</Box>
  )
}

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';

const CustomTextField = ({
    label,
    onChange,
    value,
    validationRegex,
    errorMessage,
    multiline,
    rows,
    required
}) => {
    const [error, setError] = useState(false);

    const handleChange = (e) => {
        const newValue = e.target.value;

        if (newValue.match(validationRegex) || newValue === "" || validationRegex.test(newValue)) {
            setError(false);
            onChange(newValue); 
        } else {
            setError(true);
        }
    };

    

    return (
        <TextField
        size="small"
        fullWidth
        InputProps={{
        sx:{borderRadius:'20px' ,  borderColor:'#FAED26', mt:1}
      }}
        variant="outlined"
        type="text"
        autoComplete="off"
        error={error}
        helperText={error ? errorMessage : ''}
        onChange={handleChange}
        value={value}
        multiline={multiline}
        rows={rows}
        required={required}
        />
    );
};

export default CustomTextField;

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import { Box } from "@mui/material";
import { get_transaction_movies } from "../../Api/Api";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import Nodata from "../../components/Nodata";
import Heading from "../../components/Heading/Heading";
import Spinner from "../../components/Spinner/Spinner";

export default function Transaction() {

    // const columns = ["Date/Time", "Rights", "Previous Owner", "Price", "Status"];

    const options = {
      filterType: "dropdown",
      responsive: "scroll"
    };


    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [data , setData] = useState([]);
    const navigate = useNavigate();
    const [loading , setLoading] = useState(false);
    
    const getAllData = () => {
      setLoading(true);
      const sendData = new FormData()
      sendData.append('movieId', '');
      sendData.append('buyMovieId', '');
      sendData.append('buyerId', '');
      sendData.append('fusionUserId', 1);
      axios({
        method: 'POST',
        url: get_transaction_movies,
        data:sendData,
        headers: {
          'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
      }
      }).then(res => {
        if (!res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
            let temp = [];
            let dummy;
            res.data.data.map((data, index) => {
              dummy = {
                id: index + 1,
                movieTitle: data.movieTitle,
                sellerName: data.userName,
                productionHouseName: data.productionName,
                moviePoster: data.moviePoster,
                movieRights: `${data.sellingRights}-${data.sellingParticularRights}`,
                buyerName: data.buyerName,
                movieAddedDate: data.movieAddedDate,
                NFTCreatedDate:data.NFTCreatedDate,
                movieActiveDate: data.movieActiveDate,
                movieBoughtDate:data.movieBoughtDate,
                price:data.price
              };
              temp.push(dummy);
              return null;
            });
            setData(temp);
            setLoading(false);
        } else {
          setOpen(true)
          setStatus(false)
          setColor(false)
          setMessage(res.data.message)
          setLoading(false);
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
        setLoading(false);
      });
    }
  
    useEffect(() => {
      getAllData();
    }, []);
  

    const columns = [
      {
        name: "id",
        label: "S.No",
        options: {
          filter: false,
          customBodyRender: (value, meta) => {
            return meta.rowIndex + 1;
          },
        },
      },
      {
        name: "moviePoster",
        label: "Movie",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                {
                  value ?
                    <img src={value} alt='' style={{ width: 150, height: 100, objectFit: 'contain' }} />
                    :
                    "-"
                }
              </div>
            );
          },
        },
      },
      {
        name: "movieTitle",
        label: "Movie Name",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                <div>{value ? value : "-"}</div>
              </div>
            );
          },
        },
      },
      {
        name: "movieRights",
        label: "Rights",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                <div>{value ? value : "-"}</div>
              </div>
            );
          },
        },
      },
      {
        name: "productionHouseName",
        label: "Production House Name",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                <div>{value ? value : "-"}</div>
              </div>
            );
          },
        },
      },
      {
        name: "sellerName",
        label: "Seller Name",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                <div>{value ? value : "-"}</div>
              </div>
            );
          },
        },
      },
      {
        name: "movieAddedDate",
        label: "Uploaded On",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                <div>{value ? value : "-"}</div>
              </div>
            );
          },
        },
      },
      {
        name: "NFTCreatedDate",
        label: "Digital Asset Created On",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                <div>{value ? value : "-"}</div>
              </div>
            );
          },
        },
      },
      {
        name: "movieActiveDate",
        label: "Listed On",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                <div>{value ? value : "-"}</div>
              </div>
            );
          },
        },
      },
      {
        name: "buyerName",
        label: "Buyer Name",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                <div>{value ? value : "-"}</div>
              </div>
            );
          },
        },
      },
      {
        name: "movieActiveDate",
        label: "Purchased On",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                <div>{value ? value : "-"}</div>
              </div>
            );
          },
        },
      },
      {
        name: "price",
        label: "Price",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <div>
                <div>₹{value ? value : "-"}</div>
              </div>
            );
          },
        },
      },
    ];



  return (
    <Box p={3}>
    <Box my={1} mb={2}>
    <Heading title={'Transactions'} />
    </Box>

    <>
      {loading ? 
      <div style={{display:'flex', justifyContent:'center', alignContent:'center', alignItems:'center', paddingTop:50}}>
      <Spinner />
      </div>
      :
      <>
    {
    data.length !== 0 ?
    <MUIDataTable
    title={"Transaction History"}
    data={data}
    columns={columns}
    options={options}
    />
    :
    <Box p={3}>
    <Nodata />
    </Box>
    }
    </>
}
</>
    </Box>

  )
}

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import { Container, Grid, Typography } from '@mui/material';
import Heading from '../../components/Heading/Heading';
import { add_wallet_details, get_user_payment_details, get_wallet_details } from '../../Api/Api';
import { LoadingButton } from '@mui/lab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { clusterApiUrl  , Keypair , PublicKey } from "@solana/web3.js";
import { decodeBase64, encodeBase64 } from '../../Solana.utils';
import SnackBar from '../../components/SnackBar';
import CryptoJS from 'crypto-js';
import Spinner from '../../components/Spinner/Spinner';

export default function WalletAccounts() {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [data , setData] = useState([]);
  const [loading, setLoading] = React.useState(false);

  const getAllData = () => {
    setLoading(true);
    const sendData = new FormData()
    sendData.append('fusionUserId', 1);
    axios({
      method: 'POST',
      url: get_wallet_details,
      data:sendData,
      headers: {
        'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
    }
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
        setLoading(false);
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
        setData([])
        setLoading(false);
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
      setLoading(false);
    });
  }

  useEffect(() => {
    getAllData();
  }, []);

  const keys = data ? Object.keys(data[0] || {}).filter((key) => key !=="keypair" && key !== "privateKey") : [];

        const handleChangePage = (event, newPage) => {
        setPage(newPage);
        };
        const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        };


        const decryptData = (encryptedData) => {
        try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, 'secretKey');
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
        } catch (error) {
        console.error('Error decrypting data:', error);
        return null; // or handle the error in an appropriate way
        }
        };

        const maxLength = 20;

        // Function to truncate the text
        const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
        return `${text.slice(0, maxLength)}.............`;
        }
        return text;
        };

  return (
    <Box p={2}>

    <Box my={1} mb={2}>
    <Heading title={'Generated Accounts'} />
    </Box>

    <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />

    <>
      {loading ? 
      <div style={{display:'flex', justifyContent:'center', alignContent:'center', alignItems:'center', paddingTop:50}}>
      <Spinner />
      </div>
      :

    <Grid container spacing={1}>
      <TableContainer sx={{boxShadow:8}}>
         <Table>
         <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#46444E' }}>
          <TableRow>
            {keys.map((key) => (
             <TableCell sx={{color: 'white', fontWeight: 500 , textAlign:'center'  }} key={key}>
                <Box sx={{ display: 'flex' , justifyContent:'center'}}>
                <Box sx={{ my: 'auto', textTransform: 'uppercase', textAlign: 'center', fontWeight: '600' }}>
                {key.split(/(?=[A-Z])/).join(' ')}
                </Box>
            </Box>
             </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow key={index}>
                {keys.map((key) => (
                  <TableCell align="center" key={key}>
                    {key === "publicKey" ? (
                      row[key] ? (
                          <Typography variant='subtitle2'>{truncateText(row[key], maxLength)}</Typography>
                        ) : (
                        "-"
                      )
                    )  : (
                      row[key]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
          </Grid>

}
</>
</Box>
  )
}

import React , {useState} from 'react'
import { Avatar, Box, Button, Card, Container, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import styled from 'styled-components';
import './Admin.css';
import Logo from '../../Assets/admin.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { admin_sign_in, methodPost, user_sign_in } from '../../Api/Api';
import { startUrl } from '../../Routes';

export default function AdminLogin({ setsuccessOpen, setsuccessMessage, setsuccessStatus, setsuccessColor }) {

    const CssTextField = styled(TextField)({
        '& label.Mui-focused': {
          color: '#FFC61A',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#FFC61A',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#E0E3E7',
          },
          '&:hover fieldset': {
            borderColor: '#B2BAC2',
          },
          '&.Mui-focused fieldset': {
            borderColor:  '#FFC61A',
          },
        },
      });


      const [showPassword, setShowPassword] = useState(true);
      const navigate = useNavigate();
      const [open, setOpen] = useState(false);
      const [status, setStatus] = useState(false);
      const [color, setColor] = useState(false);
      const [message, setMessage] = useState("");
      const[email, setEmail] = useState('');
      const[password, setPassword]= useState('');
      const [errMsg , setErrMsg] = useState('');

      
      const handleSubmit = (e) => {
        e.preventDefault();
        if(email !== "" && password !== ""){
          const serverData = new FormData()
          serverData.append('adminMail', email);
          serverData.append('adminPassword',password);
          serverData.append('fusionUserId',1);
            if (!navigator.onLine) {
              setMessage('Your internet is in Offline')
              setOpen(true)
              setStatus(false)
              setColor(false)
            } else {
              axios({
                method: methodPost,
                url: admin_sign_in,
                data: serverData,
                headers: {
                  'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
              }
              }).then(res => {
                if (res.data.error) {
                  setMessage(res.data.message)
                  setOpen(true)
                  setStatus(false)
                  setColor(false)
                } else {
                  localStorage.setItem('BazaarAdminAuth', true);
                  localStorage.setItem('BazaarAdminUserId', res.data.data.adminId);
                  navigate(`${startUrl}app`)
                  setsuccessOpen(true)
                  setsuccessMessage(res.data.message)
                  setsuccessStatus(true)
                  setsuccessColor(true)
        
                }
              }).catch(err => {
                alert('Oops something went wrong' + err)
              });
            }
          }
          }


  return (
    <Box className="AdminBanner">
    <Box p={2}>
        <Grid container mt={3} >

            <Grid item xs={12} md={6} display='flex' justifyContent='center' alignContent='center' alignItems='center'> 
               <img src={Logo} alt='logo' />
            </Grid>

            <Grid item xs={12} md={6} display='flex' justifyContent='center' alignContent='center' alignItems='center'>
            <Box component={Card} textAlign='left' p={2} borderRadius='20px'>
           <Box p={4}>
           <Box textAlign='center'>
            <Typography variant='h6' color='#46344E' fontWeight={600} mb={0.5}>Admin Sign In</Typography>
            </Box>
          <Stack spacing={3}>
            <Box>
                <Typography>Email Id</Typography>
              <TextField
              size="small"
              fullWidth
              InputProps={{
              sx:{borderRadius:'20px' , width:'35ch' , borderColor:'#FAED26' , mt:1}
              }}
              variant="outlined"
              type='email'
              autoComplete="email"
              onChange={(e)=>setEmail(e.target.value)}
              />
            </Box>
            <Box>
                <Typography>Password</Typography>
                <TextField
              size="small"
              fullWidth
              InputProps={{
              sx:{borderRadius:'20px' , width:'35ch' , borderColor:'#FAED26' , mt:1}
              }}
              variant="outlined"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e)=>setPassword(e.target.value)}
              helperText={errMsg}
              />
            </Box>
            <Box>
            <Button variant='contained'  onClick={handleSubmit}
            fullWidth 
            sx={{bgcolor:'#46344E', color:'#fff' , borderRadius:'3px' ,
              ':hover':{bgcolor:"#46344E" , color:'#fff'} }}>Sign In</Button>
            </Box>
          </Stack>
          </Box>
        </Box>
                </Grid>
        </Grid>

     </Box>
    </Box>
  )
}

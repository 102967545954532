import React, { useState } from 'react';
import { Box, Card, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import MenuIcon from '@mui/icons-material/Menu';
import ResponsiveBd from './ResponsiveBd';
import SolBalance from './SolBalance';

function DashbrdHeader({ mobileOpen, setMobileOpen }) {
  const navigate = useNavigate();
  const userId = localStorage.getItem('AdminPETUserId');
  const [profile , setProfile] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [search , setSearch] = useState('');

  const responsive = ResponsiveBd();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

//     useEffect(()=>{
//       const serverData = new FormData()
//       serverData.append('userId', userId);
//       axios({
//       method: "POST",
//       url: get_user,
//       data:serverData
//   }).then(res => {
//         if (res.data.error) {
//             setMessage(res.data.message)
//             setOpen(true)
//             setStatus(false)
//             setColor(false)
//         } else {
//             setOpen(true)
//             setMessage(res.data.message)
//             setProfile(res.data.data);
//             setStatus(true)
//             setColor(true)
//         }
//     }).catch(err => {
//         console.log(err)
//     });
// },[])

    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }

    function stringAvatar(name) {
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
      }

      const handleClick = () =>{
          navigate('logout');
          localStorage.clear();
      }

    return(
    <Box component={Card} py={2} display='flex' justifyContent='space-between' alignContent='center' square={true}>
      <Box>
          <IconButton aria-label="menu"
            sx={{
              display: responsive ? 'block': 'none'
            }} 
            onClick={handleDrawerToggle} >
            <MenuIcon />
          </IconButton>
      </Box>
      <Box display='flex' justifyContent='end' alignContent='center' alignItems='center' square={true}>
      <Box ml={1} mr={2}>

        <SolBalance />
      </Box>
      <Box mr={1}>
      <PowerSettingsNewIcon onClick={handleClick} color='error' sx={{verticalAlign:'middle' , cursor:'pointer'}}/>
      </Box>
      </Box>
    </Box>
    )
}

export default DashbrdHeader;
import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography, Container, Grid, Card, CardMedia, CardContent, Button, Link, List, ListItem, ListItemIcon, ListItemText, Divider, Accordion, AccordionActions, AccordionDetails, AccordionSummary, Stack, } from '@mui/material'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useNavigate } from 'react-router-dom';
import FiberManualRecordSharpIcon from '@mui/icons-material/FiberManualRecordSharp';
import MUIDataTable from "mui-datatables";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import axios from 'axios';
import { get_query } from '../../Api/Api';
import Heading from '../../components/Heading/Heading';
import Spinner from '../../components/Spinner/Spinner';
import Nodata from '../../components/Nodata';

export default function EnquiryForm() {

    const options = {
        filterType: "dropdown",
        responsive: "scroll"
      };
  
  
      const [open, setOpen] = useState(false);
      const [message, setMessage] = useState("");
      const [status, setStatus] = useState();
      const [color, setColor] = useState();
      const [data , setData] = useState([]);
      const [loading , setLoading] = useState(false);
      const navigate = useNavigate();
      
    
      const getAllData = () => {
        setLoading(true);
        const sendData = new FormData()
        sendData.append('id', '');
        sendData.append('fusionUserId', 1);
        axios({
          method: 'POST',
          url: get_query,
          data:sendData,
          headers: {
            'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
        }
        }).then(res => {
          if (!res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(true)
            setColor(true)
              let temp = [];
              let dummy;
              res.data.data.map((data, index) => {
                dummy = {
                  id: index + 1,
                  names: data.names,
                  email: data.emailId,
                  phone: data.mobileNo,
                  productionHouse:data.productionName,
                  subjects: data.subjects,
                  messages: data.messages,
                };
                temp.push(dummy);
                return null;
              });
              setData(temp);
              setLoading(false);
          } else {
            setOpen(true)
            setStatus(false)
            setColor(false)
            setMessage(res.data.message)
            setLoading(false);
          }
        }).catch(err => {
          alert('Oops something went wrong ' + err)
          setLoading(false);
        });
      }
    
      useEffect(() => {
        getAllData();
      }, []);
    
  
      const columns = [
        {
          name: "id",
          label: "S.No",
          options: {
            filter: false,
            customBodyRender: (value, meta) => {
              return meta.rowIndex + 1;
            },
          },
        },
        {
          name: "names",
          label: "Name",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                  <div>
                  <div>{value ? value : "-"}</div>
                </div>
              );
            },
          },
        },
        {
            name: "productionHouse",
            label: "Production House",
            options: {
              customBodyRender: (value, tableMeta) => {
                return (
                    <div>
                    <div>{value ? value : "-"}</div>
                  </div>
                );
              },
            },
          },
        {
          name: "email",
          label: "Email",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div>{value ? value : "-"}</div>
                </div>
              );
            },
          },
        },
        {
            name: "phone",
            label: "Mobile",
            options: {
              customBodyRender: (value, tableMeta) => {
                return (
                  <div>
                    <div>{value ? value : "-"}</div>
                  </div>
                );
              },
            },
          },
        {
          name: "subjects",
          label: "Subjects",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div>{value ? value : "-"}</div>
                </div>
              );
            },
          },
        },
        {
          name: "messages",
          label: "Messages",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div>{value ? value : "-"}</div>
                </div>
              );
            },
          },
        },
      ];
  

  useEffect(() => {
    const slideCategoryId = window.location.hash.substring(1);

    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  }, []);

  const logout = () =>{
    navigate('/admin');
    localStorage.clear();
  }

  return (
 
    <Box p={3}>
    
    <Box my={1} mb={2}>
        <Heading title={'Enquiry Form Data'} />
      </Box>


      <Container>
      <>
      {loading ? 
      <div style={{display:'flex', justifyContent:'center', alignContent:'center', alignItems:'center', paddingTop:50}}>
      <Spinner />
      </div>
      :
      <Box p={3} >
      {
      data.length !== 0 ?
      <MUIDataTable
      sx={{bgcolor:'#dfeeee'}}
      title={"Queries"}
      data={data}
      columns={columns}
      options={options}
      />
      :
      <Box p={3}>
      <Nodata />
      </Box>
      }
      </Box>
      }
      </>
      </Container>


        </Box>
      
  )
}

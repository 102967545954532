import React, {useState, Use, useEffect} from 'react'
import { Avatar, Box, Button, Card, Container, Grid, Paper, Stack, Typography, TextField, Autocomplete, Checkbox } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import SnackBar from '../../components/SnackBar';
import axios from 'axios';
import { useWallet } from '@solana/wallet-adapter-react';
import {
    clusterApiUrl,
    Keypair,
    PublicKey,
    SystemProgram,
    Transaction,
    Connection
} from '@solana/web3.js';
import { decode } from 'bs58';
import { add_buy_movies, inactive_movies } from '../../Api/Api';
import { inAppWalletAdapter, useConnection } from '../../Solana.utils';

export default function MarketDetail() {

        const [value, setValue] = useState(0);
        const navigate = useNavigate();
        const [activeStep, setActiveStep] = useState(0);
        const [skipped, setSkipped] = useState(new Set());
        const [open, setOpen] = useState(false);
        const [status, setStatus] = useState(false);
        const [color, setColor] = useState(false);
        const [message, setMessage] = useState("");
        const location = useLocation();
        const {data} = location.state;
        const [imageFile, setImageFile] = useState(null);
        const [OpenDialog, setOpenDialog] = useState(false)
        const wallet = useWallet();

        const [publicKey, setPublicKey] = useState(null);
        const [WalletConnected , setWalletConnected] = useState(true);
        const { connect, disconnect, connected } = useWallet();
        const connection = useConnection();
        const [keypair, setKeypair] = useState(null);
       
        const marketPlace = process.env.REACT_APP_MARKET_PLACE_ADDRESS;
         const handleApiKey = (apiKeyString) => {
         const publicKey = new PublicKey(apiKeyString);
         setPublicKey(publicKey);
         };
       
         useEffect(() => {
          const apiPublicKey = process.env.REACT_APP_PUBLICKEY;
         handleApiKey(apiPublicKey);
         }, []);

      
    const unliftfromSale = async () => {
      try {
        const requestData = {
          network: 'devnet',
          marketplace_address:marketPlace,
          list_state: data?.listState,
          seller_wallet:data?.sellerAddress,
          fee_payer: publicKey,
        }
    
        const axiosConfig = {
          headers: {
            "x-api-key": process.env.REACT_APP_APIKEY,
            'Content-Type': 'application/json',
          },
        };
    
        axios.post("https://api.shyft.to/sol/v1/marketplace/unlist", requestData, axiosConfig)
          .then(async response => {
            const encodedTransaction = response.data.result.encoded_transaction;
            const connection = new Connection(clusterApiUrl("devnet"), 'confirmed');
            const recoveredTransaction = Transaction.from(
              Uint8Array.from(atob(encodedTransaction), c => c.charCodeAt(0))
            );
            const signedTx = await inAppWalletAdapter.signTransaction(recoveredTransaction);
            const confirmTransaction = await connection.sendRawTransaction(signedTx.serialize());
        
            return confirmTransaction ;
          })
          .then(() => {
            unListDigital();
          })
          .catch((error) => {
            console.log('Error:', error);
            setOpen(true);
            setStatus(false);
            setColor(false);
          });
      } catch (error) {
        console.error('Error in unlistForSale:', error);
      }
    };


    const unListDigital = () =>{
      const sendData = new FormData()
      sendData.append('movieId', data?.movieId);
      sendData.append('fusionUserId', 1);
      sendData.append('rightsId', data?.rightsId);
      axios({
        method: 'POST',
        url:inactive_movies,
        data:sendData,
        headers: {
          'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
      }
      }).then(res => {
        if (!res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(true)
          setColor(true)
        } else {
          setOpen(true)
          setStatus(false)
          setColor(false)
          setMessage(res.data.message)
          navigate('/app/listing')
        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
      });
     }





        
  return (
    <Box p={3}>

   <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />

    <Box p={1}>

          <Container>

      <Grid container spacing={3} rowSpacing={5} >

      <Grid item xs={12} mb={2}>
         <img src={data?.moviePoster} alt="poster" style={{objectFit:'scale-down', width:'100%', height:550}} />
      </Grid>

{/* 
         <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>First Name</Typography>
       <Typography>{data?.producerFirstName}</Typography>
      </Grid>

         <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
      <Typography color='#46344E' fontWeight={600}>Last Name</Typography>
      <Typography>{data?.producerLastName}</Typography>
      </Grid>

        <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
      <Typography color='#46344E' fontWeight={600}>Production House</Typography>
      <Typography>{data?.productionName}</Typography>
      </Grid> */}

           <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
        <Typography color='#46344E' fontWeight={600}>Movie Title</Typography>
        <Typography>{data?.movieTitle}</Typography>
       </Grid>
 
 
          <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>Available Languages</Typography>
       <Typography>{data?.languages}</Typography>
       </Grid>
 
          <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
       <Typography color='#46344E' fontWeight={600}>Genre</Typography>
       <Typography>{data?.movieGenre}</Typography>
       </Grid>
 
 
       <Grid item xs={12}>
       <Typography color='#46344E' fontWeight={600}>Synopsis</Typography>
       <Typography>{data?.movieSynopsis}</Typography>
       </Grid>


           <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
         <Typography color='#46344E' fontWeight={600}>Rights For Sale</Typography>
         <Typography>{data?.movieRights}</Typography>
        </Grid>
  

        <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
        <Typography color='#46344E' fontWeight={600}>Movie Cast Details</Typography>
        <Typography>{data?.movieCast}</Typography>
        </Grid>
  
  
        <Grid item xs={12} md={3} display='flex' flexDirection='column' gap={1.5} flexWrap='wrap' sx={{wordWrap:'break-word', wordBreak:'break-word'}}>
        <Typography color='#46344E' fontWeight={600}>Movie Crew Details</Typography>
        <Typography>{data?.movieCrew}</Typography>
        </Grid>
    </Grid>
    <Grid container spacing={3}>
    <Grid item xs={6}>
          <Box sx={{ pt: 2 }} onClick={()=>navigate(-1)}>
            <Button fullWidth variant='contained' color='error' sx={{fontWeight:600}}>
             Go Back
            </Button>
          </Box>
          </Grid>

        <Grid item xs={6}>
          <Box sx={{ pt: 2 }}>
            <Button onClick={unliftfromSale} fullWidth variant='contained' sx={{bgcolor:'#FFC61A', fontWeight:600 , color:'#46344E', ':hover':{color:'#46344E',bgcolor:'#FFC61A'  }}}>
            UNLIST FROM SALE
            </Button>
          </Box>
          </Grid>
    </Grid>
    </Container>
    </Box>
  

  </Box>
  )
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Typography } from '@mui/material';
import { delete_movie_details } from '../../Api/Api';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteDialog({getAllData , setSelectedrightsId , selectedrightsId ,selectedMovieId,setSelectedMovieId, openDeleteDialog, setOpenDeleteDialog , movieName , setOpen , setColor , setMessage ,setStatus}) {

  const handleClose = () => {
    setOpenDeleteDialog(false);
    setSelectedMovieId('');
    setSelectedrightsId('');
  };

  const handleDelete = () =>{
    const sendData = new FormData()
    sendData.append('movieId', selectedMovieId);
    sendData.append('fusionUserId', 1);
    sendData.append('rightsId', selectedrightsId);
    axios({
      method: 'POST',
      url: delete_movie_details,
      data:sendData,
      headers: {
        'x-api-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
    }
    }).then(res => {
      if (!res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(true)
        setColor(true)
        handleClose();
        getAllData();
      } else {
        setOpen(true)
        setStatus(false)
        setColor(false)
        setMessage(res.data.message)
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }

  return (
    <React.Fragment>
      <Dialog
        open={openDeleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are You Sure that You Want to Delete this Movie ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
             <Typography fontWeight={600}>Movie:  {movieName}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='contained' color='primary'>Cancel</Button>
          <Button onClick={handleDelete} variant='contained' color='error'>Delete</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
